var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userCanSeeAnyProgress())?_c('div',{staticClass:"mb-2 agendaContainer",attrs:{"id":"stepsContainer"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse_steps'),expression:"'collapse_steps'"}],staticClass:"mr-2",class:{
        agendaTypeBtn: !_vm.configuration.steps.containerOpen,
        selectedAgendaTypeBtn: _vm.configuration.steps.containerOpen,
      },staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.toggleContainer('steps')}}},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("general.progress")))]),_c('i',{class:{
          'fas fa-angle-down': !_vm.configuration.steps.containerOpen,
          'fas fa-angle-up': _vm.configuration.steps.containerOpen,
        }})]),_c('b-collapse',{staticClass:"mt-1",attrs:{"id":"collapse_steps"}},[_c('div',{staticClass:"mb-0 agendaSubContainer"},[_c('loading',{attrs:{"active":_vm.isLoading.steps,"can-cancel":false,"color":"#f1b44c","width":70,"height":70,"loader":"dots","is-full-page":false},on:{"update:active":function($event){return _vm.$set(_vm.isLoading, "steps", $event)}}}),_c('ul',{staticStyle:{"list-style-type":"none","padding-left":"0","margin-bottom":"0"}},[_c('li',[_c('div',{staticClass:"d-flex flex-row"},[(_vm.isTaskManagementOrganisationUser)?_c('div',{staticStyle:{"width":"16px"}}):_vm._e(),_c('b-form-checkbox',{staticClass:"custom-checkbox mb-3",on:{"change":function($event){return _vm.changeAllCheckbox('steps')}},model:{value:(_vm.configuration.steps.selectAll),callback:function ($$v) {_vm.$set(_vm.configuration.steps, "selectAll", $$v)},expression:"configuration.steps.selectAll"}},[_vm._v(_vm._s(_vm.$t("general.all")))])],1)]),_vm._l((_vm.options.sources.steps),function(prog){return _c('li',{key:prog.id,staticStyle:{"display":"flex","flex-direction":"column","width":"'100%'","justify-content":"space-between"}},[_c('div',{staticClass:"d-flex flex-row"},[(_vm.isTaskManagementOrganisationUser)?_c('div',{staticStyle:{"width":"16px"}},[(prog.children.length > 0)?_c('i',{staticClass:"w-100 font-weight-bold",class:{
                    'fas fa-angle-down': !prog.toggleChildren,
                    'fas fa-angle-up': prog.toggleChildren,
                  },staticStyle:{"cursor":"pointer"},on:{"click":function($event){prog.toggleChildren = !prog.toggleChildren}}}):_vm._e()]):_vm._e(),_c('b-form-checkbox',{staticClass:"custom-checkbox mb-3",attrs:{"indeterminate":prog.indeterminate,"aria-controls":prog.id,"aria-describedby":prog.id},on:{"change":function($event){return _vm.changeCheckbox('steps', prog)}},model:{value:(prog.checked),callback:function ($$v) {_vm.$set(prog, "checked", $$v)},expression:"prog.checked"}},[_c('div',{on:{"click":function () { return (prog.toggleChildren =
                        prog.children.length > 0 ? true : false); }}},[_c('span',{class:{ 'font-weight-bold': prog.children.length > 0 }},[_vm._v(_vm._s(prog.title))]),(prog.children.length > 0)?_c('i',{staticClass:"bx bx-user-circle ml-1 font-weight-bold align-middle font-size-14"}):_vm._e()])]),_c('div',{staticClass:"ml-auto",staticStyle:{"display":"flex","flex-direction":"row"},attrs:{"v-b-toggle":prog.id}},[_c('div',{staticClass:"color-box p-1 mb-3 rounded",staticStyle:{"width":"10px","margin-left":"auto"},style:({ 'background-color': prog.color })})])],1),(prog.children.length > 0)?_c('b-collapse',{staticClass:"mb-3 pl-4 rounded",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"progress-user-checkbox",attrs:{"id":prog.id,"name":prog.id,"options":prog.children,"value-field":"id","text-field":"title","aria-describedby":ariaDescribedby,"stacked":""},on:{"change":function($event){return _vm.changeChildrenSelection('steps', prog)}},model:{value:(prog.selectedChildren),callback:function ($$v) {_vm.$set(prog, "selectedChildren", $$v)},expression:"prog.selectedChildren"}})]}}],null,true),model:{value:(prog.toggleChildren),callback:function ($$v) {_vm.$set(prog, "toggleChildren", $$v)},expression:"prog.toggleChildren"}},[_c('hr',{staticClass:"mt-1"})]):_vm._e()],1)})],2)],1)])],1):_vm._e(),(_vm.userCanSeeAnyShipper())?_c('div',{staticClass:"mb-2 agendaContainer",attrs:{"id":"deliveryContainer"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapseDelivery'),expression:"'collapseDelivery'"}],staticClass:"mr-2 agendaTypeBtn",class:{
        agendaTypeBtn: !_vm.configuration.shippers.containerOpen,
        selectedAgendaTypeBtn: _vm.configuration.shippers.containerOpen,
      },staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.toggleContainer('shippers')}}},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("general.delivery")))]),_c('i',{class:{
          'fas fa-angle-down': !_vm.configuration.shippers.containerOpen,
          'fas fa-angle-up': _vm.configuration.shippers.containerOpen,
        }})]),_c('b-collapse',{staticClass:"mt-1",attrs:{"id":"collapseDelivery"}},[_c('div',{staticClass:"mb-0 agendaSubContainer"},[_c('loading',{attrs:{"active":_vm.isLoading.shippers,"can-cancel":false,"color":"#f1b44c","width":70,"height":70,"loader":"dots","is-full-page":false},on:{"update:active":function($event){return _vm.$set(_vm.isLoading, "shippers", $event)}}}),_c('ul',{staticStyle:{"list-style-type":"none","padding-left":"0"}},[_c('li',[_c('b-form-checkbox',{staticClass:"custom-checkbox mb-3",attrs:{"checked":""},on:{"change":function($event){return _vm.changeAllCheckbox('shippers')}},model:{value:(_vm.configuration.shippers.selectAll),callback:function ($$v) {_vm.$set(_vm.configuration.shippers, "selectAll", $$v)},expression:"configuration.shippers.selectAll"}},[_vm._v(_vm._s(_vm.$t("general.all")))])],1),_vm._l((_vm.options.sources.shippers),function(shipper){return _c('li',{key:shipper.idPublic},[_c('b-form-checkbox',{staticClass:"custom-checkbox mb-3",attrs:{"checked":""},on:{"change":function($event){return _vm.changeCheckbox('shippers', shipper)}},model:{value:(shipper.checked),callback:function ($$v) {_vm.$set(shipper, "checked", $$v)},expression:"shipper.checked"}},[_vm._v(_vm._s(shipper.id == 0 ? _vm.$t("general.unassigned") : shipper.title))])],1)})],2)],1)])],1):_vm._e(),(_vm.userCanSeeAnyInstaller())?_c('div',{staticClass:"mb-2 agendaContainer",attrs:{"id":"installationContainer"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapseInstallation'),expression:"'collapseInstallation'"}],staticClass:"mr-2 agendaTypeBtn",class:{
        agendaTypeBtn: !_vm.configuration.installers.containerOpen,
        selectedAgendaTypeBtn: _vm.configuration.installers.containerOpen,
      },staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.toggleContainer('installers')}}},[_c('label',{staticClass:"mb-0"},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("general.installation")))])]),_c('i',{class:{
          'fas fa-angle-down': !_vm.configuration.installers.containerOpen,
          'fas fa-angle-up': _vm.configuration.installers.containerOpen,
        }})]),_c('b-collapse',{staticClass:"mt-1",attrs:{"id":"collapseInstallation"}},[_c('div',{staticClass:"mb-0 agendaSubContainer"},[_c('loading',{attrs:{"active":_vm.isLoading.installers,"can-cancel":false,"color":"#f1b44c","width":70,"height":70,"loader":"dots","is-full-page":false},on:{"update:active":function($event){return _vm.$set(_vm.isLoading, "installers", $event)}}}),_c('ul',{staticStyle:{"list-style-type":"none","padding-left":"0"}},[_c('li',[_c('b-form-checkbox',{staticClass:"custom-checkbox mb-3",attrs:{"checked":""},on:{"change":function($event){return _vm.changeAllCheckbox('installers')}},model:{value:(_vm.configuration.installers.selectAll),callback:function ($$v) {_vm.$set(_vm.configuration.installers, "selectAll", $$v)},expression:"configuration.installers.selectAll"}},[_vm._v(_vm._s(_vm.$t("general.all")))])],1),_vm._l((_vm.options.sources.installers),function(installer){return _c('li',{key:installer.idPublic},[_c('b-form-checkbox',{staticClass:"custom-checkbox mb-3",attrs:{"checked":""},on:{"change":function($event){return _vm.changeCheckbox('installers', installer)}},model:{value:(installer.checked),callback:function ($$v) {_vm.$set(installer, "checked", $$v)},expression:"installer.checked"}},[_vm._v(_vm._s(installer.id == 0 ? _vm.$t("general.unassigned") : installer.title))])],1)})],2)],1)])],1):_vm._e(),(_vm.userCanSeeProduction())?_c('div',{staticClass:"mb-3 agendaContainer",attrs:{"id":"productionContainer"}},[_c('b-button',{staticClass:"mr-2 agendaTypeBtn",class:{
        agendaTypeBtn: !_vm.options.sources.production,
        selectedAgendaTypeBtn: _vm.options.sources.production,
      },staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","align-items":"center"}},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("general.production")))]),_c('b-form-checkbox',{on:{"change":_vm.changeProductionCheckbox},model:{value:(_vm.options.sources.production),callback:function ($$v) {_vm.$set(_vm.options.sources, "production", $$v)},expression:"options.sources.production"}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }