<script>
//import Loader from "./loader";
import DatePicker from "vue2-datepicker";
import simplebar from "simplebar-vue";
import CalendarApiService from "@/services/calendarService";
import UserConfigMixin from "@/mixins/userConfigMixin";
import InstallersCapacities from "@/components/widgets/installersCapacities";
import WorkOrderInfoPanel from "./workorder-info-panel";
import DeletionModal from "@/components/widgets/deletion-modal.vue";
import { generalNotificationComputed } from "@/state/helpers";
import SourceSelector from "./source-selector";

/**
 * Calendar tool bar component
 */
export default {
  mixins: [UserConfigMixin],
  components: {
    DatePicker,
    simplebar,
    InstallersCapacities,
    WorkOrderInfoPanel,
    DeletionModal,
    SourceSelector
  },
  model: {
    prop: "state",
  },
  props: {
    state: {
      type: Object,
    },
    updating: {
      type: Boolean,
    },
    onCloseToolBar: {
      type: Function,
    },
  },
  data() {
    return {
      options: ["Production", "Service"],
      sourcesOptions: {
        sources: {
          steps: [],
          shippers: [],
          installers: [],
          production: false,
        },
      },
      isLoadingProgressCapacities: false,
      isLoadingInstallersCapacities: false,
      isLoading: {
        steps: false,
        shippers: false,
        installers: false,
      },
      numberOfDaysSelected: 1,
      progress: [],
      installers: [],
      shippers: [],
      workOrdersStateToDisplay: "Production",
      workOrderTypeFilter: this.$store.getters["calendar/workOrderTypes"],
      workOrdersTypesOptions: [],
      projectsContainerOpened: false,
      installationContainerOpened: false,
      capacityContainerOpened: true,
      legendContainerOpened: false,
      settings: {
        minScrollbarLength: 60,
      },
      capacitiesTotal: {},
      selectedDate: this.$dayjs().format("YYYY-MM-DD"),
      selectedWorkOrder: null,
      selectedProgressEvents: [],
      installersCapacitiesData: [],
      progressCapacitiesData: [],
    };
  },
  created() {
    this.loadSources();

    CalendarApiService.getAllJobTypes().then((response) => {
      let jt = response.data.data;
      this.workOrdersTypesOptions = this.workOrdersTypesOptions.concat(jt);
    });

    this.$bus.$on(
      "update-tool-bar-progress-capacities",
      this.updateToolBarProgressCapacities
    );
    this.$bus.$on(
      "update-tool-bar-installers-capacities",
      this.updateToolBarInstallersCapacities
    );
    this.$bus.$on("locale-updated", this.localeUpdated);
  },
  mounted() {},
  beforeDestroy() {
    this.$bus.$off(
      "update-tool-bar-progress-capacities",
      this.updateToolBarProgressCapacities
    );
    this.$bus.$off(
      "update-tool-bar-installers-capacities",
      this.updateToolBarInstallersCapacities
    );
    this.$bus.$off("locale-updated", this.localeUpdated);
  },
  computed: {
    ...generalNotificationComputed,
    deleteWorkOrderTitle() {
      return (
        this.$t("general.deleteWorkOrder") + " " + this.selectedWorkOrder?.name
      );
    },
    sortedProgressVisibleInLegend() {
      return this.progress
        .filter((x) => this.userCanSeeProgressInLegend(x))
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    },
    oneProgressSelected() {
      let checkedProgressCount = this.progress.filter((p) => {
        return p.checked == true;
      }).length;

      return this.selectAllProgress == false && checkedProgressCount == 1;
    },
    mergedProgressCapacities() {
      var selectedProgresses = this.progress.filter((p) => {
        return p.checked == true;
      });
      var mergedCapacities = [];
      for (var selectedProgress of selectedProgresses) {
        var progressCapacities = selectedProgress.dailyCapacities;

        for (var capacity of progressCapacities) {
          var existingCapacity = mergedCapacities.filter((x) => {
            return x.measureType == capacity.measureType;
          });
          if (existingCapacity.length > 0) {
            existingCapacity = existingCapacity[0];
            existingCapacity.min += capacity.min * this.numberOfDaysSelected;
            existingCapacity.max += capacity.max * this.numberOfDaysSelected;
            existingCapacity.totalValue = this.getTotalCapacityValue(
              this.selectedProgressEvents,
              existingCapacity.measureType
            );
          } else {
            var totalCapacity = {
              min: capacity.min * this.numberOfDaysSelected,
              max: capacity.max * this.numberOfDaysSelected,
              totalValue: this.getTotalCapacityValue(
                this.selectedProgressEvents,
                capacity.measureType
              ),
              measureType: capacity.measureType,
            };

            mergedCapacities.push(totalCapacity);
          }
        }
      }

      return mergedCapacities;
    },
  },
  methods: {
    selectResourceWithUsers(userIds) {
      this.state.sources.steps.splice(0, this.state.sources.steps.length);
      this.state.sources.shippers.splice(0, this.state.sources.shippers.length);
      this.state.sources.installers.splice(
        0,
        this.state.sources.installers.length
      );
      this.state.sources.production.splice(
        0,
        this.state.sources.production.length
      );
      this.sourcesOptions.sources.steps.forEach((step) => {
        step.checked = false;
        step.indeterminate = false;
        step.selectedChildren.splice(0, step.selectedChildren.length);
      });
      this.sourcesOptions.sources.shippers.forEach((shipper) => {
        shipper.checked = false;
      });
      this.sourcesOptions.sources.installers.forEach((installer) => {
        installer.checked = false;
      });
      this.sourcesOptions.sources.production = false;
      var stepWithUsers = this.sourcesOptions.sources.steps.filter(
        (x) => x.children.find((c) => userIds.includes(c.userId)) !== undefined
      );
      stepWithUsers.forEach((step) => {
        step.checked = true;
        step.selectedChildren = step.children
          .filter((x) => userIds.includes(x.userId))
          .map((child) => child.id);
        step.indeterminate =
          step.checked && step.selectedChildren.length !== step.children.length;
      });
      this.state.sources.steps.push(
        ...stepWithUsers.map((x) => {
          return {
            id: x.id,
            title: x.title,
            children: x.children.filter((c) => userIds.includes(c.userId)),
            resourceType: x.resourceType,
            resourceTypeOrder: x.resourceTypeOrder,
            color: x.color,
            order: x.order,
          };
        })
      );
    },
    getTotalCapacityValue(events, measureType) {
      var total = 0;

      for (var event of events) {
        var eventMeasureTypeValue = event.extendedProps.values.filter((x) => {
          return x.measureType == measureType;
        });
        if (eventMeasureTypeValue.length == 0) continue;

        eventMeasureTypeValue = eventMeasureTypeValue[0];
        total += eventMeasureTypeValue.value;
      }

      return total;
    },
    localeUpdated() {
      this.$forceUpdate();
    },
    updateToolBarProgressCapacities: function (data) {
      this.setProgressCapacities(data);
    },
    updateToolBarInstallersCapacities(data) {
      this.setInstallersCapacities(data);
    },
    async setWorkOrderInfo(event) {
      if (event != null && event.extendedProps.userCreated == false) {
        let workOrderResponse =
          await CalendarApiService.getWorkOrderByIdPublicNoWorkOrderSteps(
            event.extendedProps.workOrderIdPublic
          );
        this.trackEvent("DisplayWorkOrder");

        this.selectedWorkOrder = workOrderResponse.data.data;

        //Get work order info
        this.isLoadingWorkOrderInfo = true;
        CalendarApiService.getWorkOrderProgressAppointments(
          this.selectedWorkOrder.idPublic
        ).then((response) => {
          let progressAppointments = response.data.data;
          if (this.selectedWorkOrder != null) {
            this.$set(
              this.selectedWorkOrder,
              "progressAppointments",
              progressAppointments
            );
          }
          this.isLoadingWorkOrderInfo = false;
        });
      } else {
        this.selectedWorkOrder = null;
      }
    },
    async setProgressCapacities(data) {
      var startDate = data.startDate;
      var nbOfDaysSelected = data.numberOfDaysSelected;
      var events = data.events;

      if (events.length == 0) {
        this.progressCapacitiesData = [];
        return;
      }

      let stepEvents = events.filter((e) => e.extendedProps.eventType == "StepEvent");

      let stepIds = stepEvents
        .flatMap((e) => e.extendedProps.stepIds)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      this.isLoadingProgressCapacities = true;

      let capacityRanges = await CalendarApiService.getProgressCapacities(stepIds).then((response) => {
        return response.data.data
      })

      let totalCapacitiesByMeasureType = this.getTotalCapacitiesByMeasureType(
        capacityRanges,
        nbOfDaysSelected,
        data.blockerEvents
      );

      let workOrderStepsUniqueIdPublic = stepEvents
        .map((e) => e.extendedProps.workOrderStepIdPublic)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      //aller chercher seulement les values pour les capacites qui sont affiches
      //unique values
      let filter = {
        measureTypes: capacityRanges
          .map((x) => {
            return x.measureType;
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          }),
      };

      let webWorkOrderStepValues = (
        await CalendarApiService.getWorkOrderStepsValues(
          workOrderStepsUniqueIdPublic,
          filter
        )
      ).data.data;
      
      webWorkOrderStepValues.map((stepValue) => {
        let match = events.find(
          (x) =>
          x._def.extendedProps.workOrderStepIdPublic ==
          stepValue.workOrderStepIdPublic
        );
        
        if (match && match._def.extendedProps.status == "Completed") {
          stepValue.progressStatus = "completed";
        } else {
          stepValue.progressStatus = "notCompleted";
        }
      });
      
      let workOrderStepValues = [];

      webWorkOrderStepValues.forEach((workorder) => {
        let event = stepEvents.find(
          (x) =>
            x.extendedProps.workOrderStepIdPublic ==
            workorder.workOrderStepIdPublic
        );

        let measureType = workorder.measureType;

        let eventCapacity = capacityRanges.filter((x) => {
          return (
            x.stepId == event.extendedProps.stepIds[0] &&
            x.measureType == measureType
          );
        });

        if (eventCapacity.length > 0) {
          workOrderStepValues.push(workorder);
        }
      });

      this.isLoadingProgressCapacities = false;

      //aller chercher les valeurs totales des projets
      //traverser les journee et compter pour chaque projet dans la journee la valeur / nb de jours que span levent
      var totalWorkOrderStepValuesByMeasureType = {};

      if (startDate != null) {
        //Selection des case(jour) dans le calendrier
        var currentDay = 1;
        while (currentDay <= nbOfDaysSelected) {
          var date = this.$dayjs(startDate).add(currentDay - 1, "day");
          var eventsInDay = this.getEventsInDate(date, events);

          let eventsWithValues = this.getEventsWithWorkOrderStepValues(
            eventsInDay,
            workOrderStepValues
          );
          this.setTotalProgressValuesByMeasureTypeForDate(
            eventsWithValues,
            totalWorkOrderStepValuesByMeasureType
          );

          currentDay++;
        }
      } else {
        //Selection des batons devent directement dans le calendrier -> pas de start date
        totalWorkOrderStepValuesByMeasureType = this.getTotalWorkOrderStepValuesByMeasureType(workOrderStepValues);
      }

      this.progressCapacitiesData = this.setCapacitiesData(
        totalWorkOrderStepValuesByMeasureType,
        totalCapacitiesByMeasureType
      );

    },
    getEventsWithWorkOrderStepValues(events, workOrderStepValues) {
      let data = [];

      for (var event of events) {
        let workOrderStepIdPublic = event.extendedProps.workOrderStepIdPublic;

        let model = {
          start: event.start,
          end: event.end,
          values: workOrderStepValues.filter((x) => {
            return x.workOrderStepIdPublic == workOrderStepIdPublic;
          }),
        };

        data.push(model);
      }

      return data;
    },
    getEventsWithWorkOrderValues(events, workOrderValues) {
      let data = [];

      for (var event of events) {
        let workOrderIdPublic = event.extendedProps.workOrderIdPublic;

        let model = {
          start: event.start,
          end: event.end,
          values: workOrderValues.filter((x) => {
            return x.workOrderIdPublic == workOrderIdPublic;
          }),
        };

        data.push(model);
      }

      return data;
    },
    async setInstallersCapacities(data) {
      var startDate = data.startDate;
      var nbOfDaysSelected = data.numberOfDaysSelected;
      var events = data.events;

      if (events.length == 0) {
        this.installersCapacitiesData = [];
        return;
      }

      this.isLoadingInstallersCapacities = true;

      //aller chercher les events dinstallation
      //avoir une liste de tous les installateurs(pas de doublons)
      //additionner leurs capacites daily pour toutes les metriques
      let installersIdPublicUnique = events
        .filter((e) => {
          return (
            e.extendedProps.eventType == "InstallationEvent" &&
            e.extendedProps.installerIds != null
          );
        })
        .flatMap((e) => e.extendedProps.installerIds)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      let capacities = [];
      if (installersIdPublicUnique.length !== 0) {
        //aller chercher les capacites pour les installateurs
        var capacitiesResponse =
          await CalendarApiService.getInstallersCapacities(
            installersIdPublicUnique
          );

        capacities = capacitiesResponse.data.data;
      }

      let eventsFilter = events.filter((e) => {
        return e.extendedProps.userCreated == false;
      });
      let workOrdersIdPublic = eventsFilter.map((e) => {
        return e.extendedProps.workOrderIdPublic;
      });
      let workOrdersIdPublicUnique = workOrdersIdPublic.filter(
        (value, index, self) => {
          return self.indexOf(value) === index;
        }
      );

      //aller chercher seulement les values pour les capacites qui sont affiches
      //unique values
      let filter = {
        measureTypes: capacities
          .map((x) => {
            return x.measureType;
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          }),
      };
      var workOrderValuesResponse =
        await CalendarApiService.getWorkOrdersValuesByIdPublic(
          workOrdersIdPublicUnique,
          filter
        );
      var workOrderValues = workOrderValuesResponse.data.data;

      var totalCapacitiesByMeasureType = this.getTotalCapacitiesByMeasureType(
        capacities,
        nbOfDaysSelected,
        data.blockerEvents
      );

      this.isLoadingInstallersCapacities = false;

      //aller chercher les valeurs totales des projets
      //traverser les journee et compter pour chaque projet dans la journee la valeur / nb de jours que span levent
      let totalWorkOrderValuesByMeasureType = {};

      if (startDate != null) {
        //Selection des case(jour) dans le calendrier
        let currentDay = 1;
        while (currentDay <= nbOfDaysSelected) {
          let date = this.$dayjs(startDate).add(currentDay - 1, "day");
          let eventsInDay = this.getEventsInDate(date, events);

          let eventsWithValues = this.getEventsWithWorkOrderValues(
            eventsInDay,
            workOrderValues
          );
          this.setTotalValuesByMeasureTypeForDate(
            eventsWithValues,
            totalWorkOrderValuesByMeasureType
          );

          currentDay++;
        }
      } else {
        //Selection des batons devent directement dans le calendrier -> pas de start date
        totalWorkOrderValuesByMeasureType =
          this.getTotalWorkOrderValuesByMeasureType(workOrderValues);
      }

      this.installersCapacitiesData = this.setCapacitiesData(
        totalWorkOrderValuesByMeasureType,
        totalCapacitiesByMeasureType
      );
    },
    getTotalWorkOrderValuesByMeasureType(values) {
      let totalWorkOrderValuesByMeasureType = {};

      for (var value of values) {
        let measureType = value.measureType;
        let valueToAssign = value.value;

        if (totalWorkOrderValuesByMeasureType[measureType] == undefined) {
          totalWorkOrderValuesByMeasureType[measureType] = valueToAssign;
        } else {
          totalWorkOrderValuesByMeasureType[measureType] += valueToAssign;
        }
      }

      return totalWorkOrderValuesByMeasureType;
    },
    getTotalWorkOrderStepValuesByMeasureType(values) {
      let totalWorkOrderStepValuesByMeasureType = [];

      for (var value of values) {
          let measureType = value.measureType;
          let valueToAssign = value.value
        
          if (totalWorkOrderStepValuesByMeasureType[measureType] == undefined) {

            totalWorkOrderStepValuesByMeasureType[measureType] = {};

            totalWorkOrderStepValuesByMeasureType[measureType][value.progressStatus] = valueToAssign;

          } else if (totalWorkOrderStepValuesByMeasureType[measureType][value.progressStatus] == undefined) {

            totalWorkOrderStepValuesByMeasureType[measureType][value.progressStatus] = valueToAssign

          } else {
            totalWorkOrderStepValuesByMeasureType[measureType][value.progressStatus] += valueToAssign;
          }
        }

      return totalWorkOrderStepValuesByMeasureType;
    },
    setCapacitiesData(totalValuesByMeasureType, totalCapacitiesByMeasureType) {
      //reset data array
      let capacitiesData = [];

      for (let key in totalCapacitiesByMeasureType) {
        let measureType = key;
        let totalValue = totalValuesByMeasureType[measureType] != undefined ? totalValuesByMeasureType[measureType] : 0;
        let totalCompleted = totalValuesByMeasureType[measureType]?.completed ?? 0;
        let totalNotCompleted = totalValuesByMeasureType[measureType]?.notCompleted ?? 0

        let model = {
          min: totalCapacitiesByMeasureType[key].min,
          max: totalCapacitiesByMeasureType[key].max,
          totalValue: totalValue,
          totalCompleted: totalCompleted,
          totalNotCompleted: totalNotCompleted,
          measureType: measureType,
        };
        capacitiesData.push(model);
      }
      return capacitiesData;
    },
    setTotalValuesByMeasureTypeForDate( eventsForDate, totalWorkOrderValuesByMeasureType ) {
      for (var event of eventsForDate) {
        var start = this.$dayjs(event.start);
        var end = this.$dayjs(event.end);
        var eventDaySpan = Math.abs(start.diff(end, "day", true));
        //si on ne remet pas a 1, on va multiplier le value...
        if (event.end === null || eventDaySpan < 1) {
          eventDaySpan = 1;
        }

        var workOrderStepValues = event.values;

        for (var value of workOrderStepValues) {
          let measureType = value.measureType;
          let valueToAssign = value.value / eventDaySpan;
        
          if (totalWorkOrderValuesByMeasureType[measureType] == undefined) {

            totalWorkOrderValuesByMeasureType[measureType] = valueToAssign;

          } else {
            totalWorkOrderValuesByMeasureType[measureType] += valueToAssign;
          }
        }
      }
    },
    setTotalProgressValuesByMeasureTypeForDate( eventsForDate, totalWorkOrderStepValuesByMeasureType ) {
      for (var event of eventsForDate) {
        var start = this.$dayjs(event.start);
        var end = this.$dayjs(event.end);
        var eventDaySpan = Math.abs(start.diff(end, "day", true));
        //si on ne remet pas a 1, on va multiplier le value...
        if (event.end === null || eventDaySpan < 1) {
          eventDaySpan = 1;
        }

        var workOrderStepValues = event.values;

        for (var value of workOrderStepValues) {
          let measureType = value.measureType;
          let valueToAssign = value.value / eventDaySpan;
        
          if (totalWorkOrderStepValuesByMeasureType[measureType] == undefined) {

            totalWorkOrderStepValuesByMeasureType[measureType] = {};

            totalWorkOrderStepValuesByMeasureType[measureType][value.progressStatus] = valueToAssign;

          } else if (totalWorkOrderStepValuesByMeasureType[measureType][value.progressStatus] == undefined) {

            totalWorkOrderStepValuesByMeasureType[measureType][value.progressStatus] = valueToAssign

          } else {
            totalWorkOrderStepValuesByMeasureType[measureType][value.progressStatus] += valueToAssign;
          }
        }
      }
    },
    getEventsInDate(date, events) {
      var eventsInDate = [];

      for (var event of events) {
        var eventStart = this.$dayjs(event.start);
        var eventEnd = this.$dayjs(event.end);
        var selectedStart = this.$dayjs(date);
        var selectedEnd = this.$dayjs(date).add(1, "day");

        let isOutsideRange =
          eventEnd <= selectedStart || eventStart >= selectedEnd;
        if (!isOutsideRange) {
          eventsInDate.push(event);
        }
      }

      return eventsInDate;
    },
    dateRangeOverlaps(a_start, a_end, b_start, b_end) {
      if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
      if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
      if (b_start < a_start && a_end < b_end) return true; // a in b
      return false;
    },
    getWorkOrderStepsUniqueIdPublic(events) {
      let progressEvents = events.filter((e) => {
        return e.extendedProps.eventType == "StepEvent";
      });
      let workOrderSteps = progressEvents.map((e) => {
        return e.extendedProps.workOrderStepIdPublic;
      });
      let workOrderStepsIdPublicUnique = workOrderSteps.filter(
        (value, index, self) => {
          return self.indexOf(value) === index;
        }
      );

      return workOrderStepsIdPublicUnique;
    },
    getNumberOfBlockedDays(events, nbOfDaysSelected) {
      // eslint-disable-next-line no-unused-vars
      var start = new Date(Math.min(...events.map((x) => x.start.getTime())));
      var blockedDays = 0;
      var dayCounted = 0;
      while (dayCounted < nbOfDaysSelected) {
        var currentDate = new Date(this.$dayjs(start).add(dayCounted, "day"));
        var blockerEvents = events.filter(
          (x) => x.start <= currentDate && x.end > currentDate
        );
        if (blockerEvents.length > 0) {
          blockedDays++;
        }
        dayCounted++;
      }
      return blockedDays;
    },
    getTotalCapacitiesByMeasureType(
      capacities,
      nbOfDaysSelected = 1,
      blockerEvents
    ) {
      var totalCapacitiesByMeasureType = [];
      for (var capacity of capacities) {
        var specificBlockerEvents =
          capacity.progressId != null
            ? blockerEvents.filter((x) =>
                x.extendedProps.selectedProgress.includes(capacity.progressId)
              )
            : null;
        specificBlockerEvents =
          specificBlockerEvents ??
          blockerEvents.filter((x) =>
            x.extendedProps.selectedInstallers.includes(
              capacity.installerIdPublic
            )
          );
        var blockedDays = this.getNumberOfBlockedDays(
          specificBlockerEvents,
          nbOfDaysSelected
        );
        var measureType = capacity.measureType;
        let min = capacity.min * (nbOfDaysSelected - blockedDays);
        let max = capacity.max * (nbOfDaysSelected - blockedDays);

        if (totalCapacitiesByMeasureType[measureType] == undefined) {
          //add key
          let model = {
            min: min,
            max: max,
          };
          totalCapacitiesByMeasureType[measureType] = model;
        } else {
          //accumule capacity
          totalCapacitiesByMeasureType[measureType].min += min;
          totalCapacitiesByMeasureType[measureType].max += max;
        }
      }

      return totalCapacitiesByMeasureType;
    },
    loadSources() {
      Promise.all([
        this.getProgressForDefaultProgressAgenda(),
        this.getInstallersForDefaultDeliveryAgenda(),
        this.getShippersForDefaultDeliveryAgenda(),
      ]).then(() => {
        this.getProductionForDefaultAgenda(), this.$emit("all-set");
      });
    },
    async getProductionForDefaultAgenda() {
      this.$refs.sourceSelector.configureProductionSource();
      return Promise.resolve();
    },
    async getProgressForDefaultProgressAgenda() {
      this.isLoading["steps"] = true;
      var response = await CalendarApiService.getAllProgressListView();
      this.progress.push(
        ...response.data.data
          .filter((x) => this.userCanSeeProgress(x))
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
      );
      this.$refs.sourceSelector.configureStepSources(this.progress);
      this.isLoading["steps"] = false;
      return Promise.resolve();
    },
    async getShippersForDefaultDeliveryAgenda() {
      this.isLoading["shippers"] = true;
      var response = await CalendarApiService.getShippers();
      this.shippers.push(
        ...response.data.data
          .filter((x) => this.userCanSeeShipper(x))
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
      );

      this.$refs.sourceSelector.configureShipperSources(this.shippers);

      this.isLoading["shippers"] = false;
      return Promise.resolve();
    },
    async getInstallersForDefaultDeliveryAgenda() {
      this.isLoading["installers"] = true;
      var response = await CalendarApiService.getInstallers();

      this.installers.push(
        ...response.data.data
          .filter((x) => this.userCanSeeInstaller(x))
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
      );

      this.$refs.sourceSelector.configureInstallerSources(this.installers);
      this.isLoading["installers"] = false;
      return Promise.resolve();
    },
    closeToolBar() {
      this.$root.$emit("closeToolbar");
      this.onCloseToolBar();
    },
    toggleLegendContainer() {
      this.legendContainerOpened = !this.legendContainerOpened;
    },
    toggleProjectsContainer() {
      this.projectsContainerOpened = !this.projectsContainerOpened;
    },
    toggleCapacityContainer() {
      this.capacityContainerOpened = !this.capacityContainerOpened;
    },
    getCapacityValue(dailyCapacity) {
      let total = this.capacitiesTotal[dailyCapacity.measureType];
      if (total) {
        return total;
      } else {
        return 0;
      }
    },
    changeDate(newDate) {
      this.$root.$emit("change-tool-bar-date", newDate);
    },
    isUnder(capacity, min) {
      let value = this.getCapacityValue(capacity);
      return value < min;
    },
    isOver(capacity, max) {
      let value = this.getCapacityValue(capacity);
      return value > max;
    },
    isInRange(capacity, min, max) {
      return !this.isUnder(capacity, min) && !this.isOver(capacity, max);
    },
    deleteSelectedWorkOrder() {
      let workOrderIdPublic = this.selectedWorkOrder.idPublic;
      this.$refs.workOrderDeletionModal.show().then(() => {
        CalendarApiService.deleteWorkOrder(workOrderIdPublic).then(() => {
          this.trackEvent("DeleteWorkOrder");
          this.$emit("reloadCalendarEvents");
          this.$store.dispatch("calendar/fetchAlerts");
          this.selectedWorkOrder = null;
        });
      });
    },
    editEvent(event) {
      this.$emit("edit-event", event);
    },
    selectWorkOrderType() {
      this.$store.commit(
        "calendar/setWorkOrderTypes",
        this.workOrderTypeFilter
      );
      this.$nextTick(() => {
        this.$emit("reloadCalendarEvents");
      });
    },
  },
};
</script>
<template>
  <!-- <Loader :loading="updating"> -->
  <simplebar
    :settings="settings"
    :class="{
      calendarToolsContent: true,
      row: true,
      'no-gutters': true,
      withNotification: hasGeneralNotification,
    }"
    style="
      padding: 0;
      padding-top: 1.5rem !important;
      position: absolute;
      overflow: auto;
      background: #f1f1f2;
    "
    ref="calendarToolBar"
    id="calendar-tool-bar"
  >
    <div class="row">
      <div class="col-12" style="padding-left: 10px; padding-right: 10px">
        <div
          class="mb-3"
          style="display: flex; justify-content: space-between"
          :class="{ 'flex-column': !isTaskManagementOrganisationUser }"
        >
          <!-- <i style="font-size:2em; margin-right:15px; margin-bottom:5px; cursor:pointer; color: #6d6e71;" class="far fa-calendar-alt"></i> -->
          <div
            id="workOrderFilters"
            style="width: 100%"
            v-if="!isTaskManagementOrganisationUser"
          >
            <div class="mb-1">
              <label>{{ $t("general.workOrdersType") }}</label>
              <multiselect
                v-model="workOrderTypeFilter"
                :options="workOrdersTypesOptions"
                label="name"
                track-by="idPublic"
                :show-labels="false"
                @input="selectWorkOrderType"
                :multiple="true"
                :placeholder="$t('general.selectWorkOrderTypes')"
              >
              </multiselect>
            </div>
          </div>
          <div
            :class="{
              task_mngm_date: isTaskManagementOrganisationUser,
              'mt-3': !isTaskManagementOrganisationUser,
              'mb-3': isTaskManagementOrganisationUser,
            }"
          >
            <date-picker
              class="sideBarDatePicker"
              @change="changeDate"
              :clearable="false"
              :format="this.$dayjs.localeData().longDateFormat('L')"
              value-type="YYYY-MM-DD"
              v-model="selectedDate"
              :first-day-of-week="1"
              lang="fr"
            >
            </date-picker>
          </div>
          <i
            @click="closeToolBar()"
            :class="{
              'closeToolBar-task-mngm': isTaskManagementOrganisationUser,
              'closeToolBar-wo-task-mngm': !isTaskManagementOrganisationUser,
            }"
            style="
              position: absolute;
              right: 12px;
              height: fit-content;
              font-size: 2em;
              color: #cacaca;
              margin-left: 15px;
              margin-bottom: 5px;
              cursor: pointer;
            "
            class="fas fa-chevron-left"
          ></i>
        </div>

        <!-- //MULTI PROGRESS CAPACITIES -->
        <div
          v-if="progressCapacitiesData.length > 0"
          id="capacityContainer"
          class="card mb-3 infoContainer"
        >
          <b-button
            class="mr-2 infoContainer"
            @click="toggleCapacityContainer()"
            style="
              border: none;
              background: white;
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
            "
            >{{ $t("general.capacitiesProgress") }}
            <i
              v-bind:class="{
                'fas fa-angle-down': !capacityContainerOpened,
                'fas fa-angle-up': capacityContainerOpened,
              }"
            ></i
          ></b-button>
          <b-collapse v-model="capacityContainerOpened" class="">
            <b-card style="margin-top: -6px; margin-bottom: 0">
              <loading
                :active.sync="isLoadingProgressCapacities"
                :can-cancel="false"
                color="#f1b44c"
                :width="70"
                :height="70"
                loader="dots"
                :is-full-page="false"
              ></loading>
              <div
                v-for="(capacity, index) in progressCapacitiesData"
                :key="index"
                class="mb-3"
              >
                <b-progress 
                  class="mb-1" 
                  height="20px"
                  :max="capacity.max" 
                  show-value
                  :class="{
                    underCapacity: capacity.totalCompleted + capacity.totalNotCompleted <= capacity.min,
                    overCapacity: capacity.totalCompleted + capacity.totalNotCompleted >= capacity.max,
                    inRangeCapacity:
                      capacity.totalCompleted + capacity.totalNotCompleted >= capacity.min &&
                      capacity.totalCompleted + capacity.totalNotCompleted<= capacity.max,
                  }"
                >
                  <b-progress-bar
                    v-b-tooltip.hover 
                    :value="capacity.totalCompleted"
                    :title="capacity.totalCompleted"
                  >
                  </b-progress-bar>
                  <b-progress-bar
                    v-b-tooltip.hover 
                    :title="capacity.totalNotCompleted"
                    :value="capacity.totalNotCompleted" 
                    :class="{
                      underCapacityNotCompleted: capacity.totalCompleted + capacity.totalNotCompleted <= capacity.min,
                      overCapacityNotCompleted: capacity.totalCompleted + capacity.totalNotCompleted >= capacity.max,
                      inRangeCapacityNotCompleted:
                        capacity.totalCompleted + capacity.totalNotCompleted >= capacity.min &&
                        capacity.totalCompleted + capacity.totalNotCompleted<= capacity.max,
                    }"
                    animated
                  >
                  </b-progress-bar>
                </b-progress>

                <div style="display: flex; justify-content: space-between">
                  <small style="font-weight: 500">
                    {{ $t("general." + capacity.measureType) }} : {{ capacity.totalCompleted + capacity.totalNotCompleted }}
                  </small>
                  <small>{{ capacity.min }} | {{ capacity.max }}</small>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </div>

        <InstallersCapacities
          :isLoading="isLoadingInstallersCapacities"
          :capacities="installersCapacitiesData"
        >
        </InstallersCapacities>
        <SourceSelector
          key="progress"
          v-model="state.sources"
          :options="sourcesOptions"
          :isLoading="isLoading"
          @change="() => this.$emit('sourceChange', this.state.sources)"
          ref="sourceSelector"
        />
        <WorkOrderInfoPanel
          v-if="selectedWorkOrder != null"
          v-model="selectedWorkOrder"
          @btn-delete-clicked="deleteSelectedWorkOrder"
          @edit-event="editEvent"
        />
        <div
          id="legendContainer"
          class="card mb-2 infoContainer shadow-none"
          v-if="userCanSeeAnyProgressInLegend()"
        >
          <b-button
            class="mr-2 infoContainer"
            @click="toggleLegendContainer()"
            style="
              border: none;
              background: white;
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
            "
            v-b-toggle="'collapseLegend'"
            >{{ $t("general.legend") }}
            <i
              v-bind:class="{
                'fas fa-angle-down': !legendContainerOpened,
                'fas fa-angle-up': legendContainerOpened,
              }"
            ></i
          ></b-button>
          <b-collapse id="collapseLegend" class="">
            <b-card
              style="margin-top: -6px; margin-bottom: 0"
              class="shadow-none"
            >
              <ul style="list-style-type: none; padding-left: 0">
                <li
                  v-for="(progress, index) in sortedProgressVisibleInLegend"
                  :key="progress.id"
                >
                  <b-badge
                    pill
                    class="mb-2"
                    style="font-size: 1.2em"
                    :style="{ 'background-color': progress.colorHexa }"
                  >
                    {{ index + 1 }}. {{ progress.name }}
                  </b-badge>
                </li>
              </ul>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </div>
    <DeletionModal
      ref="workOrderDeletionModal"
      :content="this.$t('general.areYouSureDeleteWorkOrder')"
      :title="this.$t('general.deleteWorkOrder')"
    />
  </simplebar>
  <!-- </Loader> -->
</template>

<style scoped>
.closeToolBar-task-mngm {
  top: 5px;
}
.closeToolBar-wo-task-mngm {
  top: -7px;
}
.task_mngm_date {
  width: 88%;
}
#calendar-tool-bar {
  z-index: 2;
}

.calendarToolsContent {
  scrollbar-width: thin;
}

.calendarToolsContent >>> .mx-datepicker-main {
  border-radius: 0.3rem !important;
}

.calendarToolsContent >>> .mx-calendar {
  width: auto;
}

.calendarToolsContent .workOrderType >>> .multiselect__tags {
  background-color: #f1f1f2 !important;
  border: none !important;
}

.calendarToolsContent .workOrderType >>> .multiselect__select {
  top: -1px;
}

.calendarToolsContent
  .workOrderType
  >>> .multiselect__tags
  .multiselect__single {
  background-color: #f1f1f2 !important;
  font-size: 1.05em !important;
}

.calendarToolsContent
  .workOrderType
  >>> .multiselect__tags
  .multiselect__input {
  background-color: #f1f1f2 !important;
  font-size: 1em !important;
}

.calendarToolsContent.withNotification {
  top: 102.5px !important;
}
</style>