<script>
// eslint-disable-next-line no-unused-vars
import CalendarApiService from "@/services/calendarService";

export default {
    model: {
    },
    components: {
    },
    props: {
    },
    data() {
        return {
            workOrderTypeFilter: this.$store.getters['calendar/workOrderTypes'],
            workOrdersTypesOptions: [],
        }
    },
    created() {
        CalendarApiService.getAllJobTypes().then((response) => {
            let jt = response.data.data;
            this.workOrdersTypesOptions = this.workOrdersTypesOptions.concat(jt);
        });

    },
    computed: {
    },
    watch: {
    },
    methods: {
        selectWorkOrderType() {
            this.$store.commit("calendar/setWorkOrderTypes", this.workOrderTypeFilter);
            this.$nextTick(() => {
                this.$emit("reloadCalendarEvents");
            });
        },
    }
};
</script>
<template>
    <multiselect id="workOrderFilters" style="width: 100%" v-model="workOrderTypeFilter" class="mb-1"
        :options="workOrdersTypesOptions" label="name" track-by="idPublic" :show-labels="false"
        @input="selectWorkOrderType" :multiple="true" :placeholder="$t('general.selectWorkOrderTypes')">
    </multiselect>
</template>
