<script>
import DatePicker from "vue2-datepicker";
import CalendarApiService from "@/services/calendarService";
import { Sketch } from "vue-color";
import UserConfigMixin from "@/mixins/userConfigMixin";
import CompleteStepsBeforeModal from "@/router/views/modals/completeStepsBeforeModal";
import UsersMultiSelect from "@/components/users-multi-select.vue";
import ConfirmModal from "../confirm-modal.vue";

export default {
  mixins: [UserConfigMixin],
  components: {
    DatePicker,
    Sketch,
    CompleteStepsBeforeModal,
    UsersMultiSelect,
    ConfirmModal,
  },
  data() {
    return {
      showModal: false,
      eventForm: this.getEmptyEventForm(),
      eventInfo: null,
      categories: [],
      itemsList: [],
      usersList: [],
      oldStartDate: null,
      openValues: false,
      showColorPicker: false,
      isLoading: false,
      isLoadingInitial: false,
      completePreviousStepsOnComplete: true,
      emailLoading: false,
      resolveFunction: null,
      rejectFunction: null,
    };
  },
  created() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    documentation_event() {
      return {
        id: this.eventForm.userCreated
          ? this.eventForm.id
          : this.eventForm.title,
        title: this.eventForm.title,
        userCreated: this.eventForm.userCreated,
      };
    },
    documentation_options() {
      return {
        folders: this.userDocumentationFolders
      };
    },
    isLoadingOverlay() {
      return this.isLoading || this.isLoadingInitial;
    },
    statusList() {
      return this.$store.state.calendar.appointmentStatus;
    },
    googleMapLink() {
      return "http://maps.google.com/?q=" + this.clientAddress;
    },
    clientAddressAndPhone() {
      let phone = this.eventForm.workOrder?.clientPhone;
      phone != null;
      return this.clientAddress + (phone != null ? phone : "");
    },
    clientAddress() {
      let address = this.eventForm.workOrder?.clientAddress;
      let address2 = this.eventForm.workOrder?.clientAddressLine2;
      let city = this.eventForm.workOrder?.clientCity;
      let state = this.eventForm.workOrder?.clientState;
      let zipCode = this.eventForm.workOrder?.clientZipCode;

      return (
        (address != null ? address + ", " : "") +
        (address2 != null ? address2 + ", " : "") +
        (city != null ? city + ", " : "") +
        (state != null ? state + ", " : "") +
        (zipCode != null ? zipCode + ", " : "")
      );
    },
    startDate() {
      return this.eventForm.startDate;
    },
    itemLabel() {
      let label = "";
      switch (this.eventForm.type) {
        case "DeliveryEvent":
          label = "Shipper";
          break;
        case "InstallationEvent":
          label = "Installer";
          break;
      }

      return label;
    },
    isStarted() {
      return this.eventForm.status == "Started";
    },
    isCompleted() {
      return this.eventForm.status == "Completed";
    },
    isPending() {
      return this.eventForm.status == "Pending";
    },
    resourceHasEmail() {
      if (this.eventForm.type == "installation") {
        let found = this.eventForm.installers?.find((el) =>
          this.itemsList.find((x) => x.idPublic == el.idPublic && x.hasEmails)
        );
        if (found) {
          return true;
        }
      }
      if (this.eventForm.type == "delivery") {
        let shipper =
          this.eventForm.shipper ?? this.eventForm.workOrder.shipper;
        return !shipper
          ? null
          : this.itemsList.find((x) => x.idPublic === shipper.idPublic)
              ?.hasEmails ?? false;
      }
      return false;
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async openEditEvent(eventId) {
      this.isLoading = true;
      try {
        let eventResponse = await CalendarApiService.getEventById(eventId);
        return new Promise(async (resolve, reject) => {
          this.isLoadingInitial = true;
          this.resolveFunction = resolve;
          this.rejectFunction = reject;
          this.eventInfo = eventResponse.data;
          if (this.userCanEditEvent(this.eventInfo)) {
            this.setEventFormData(eventResponse.data);

            this.showModal = true;
          } else {
            this.closeModal();
          }
        });
      } finally {
        this.isLoading = false;
      }
    },
    // async openEditEventDialog(data, code) {
    //   this.eventInfo = data.eventInfo;
    //   code ? (this.duplicate = true) : (this.duplicate = false);

    //   this.openValues = false;

    //   this.isLoadingInitial = true;
    //   await this.loadItemsDropdown(
    //     data.eventInfo.event._def.extendedProps.eventType
    //   );
    //   this.setEventFormData(data.eventInfo.event);

    //   this.showModal = true;
    // },
    setMissingValues() {
      for (let measureType of this.measureTypes) {
        let exist = this.eventForm.values.filter((values) => {
          return values.measureType == measureType;
        });

        //Si la capacite nest pas dans la liste, lajouter pour permettre de la creer pour le work order
        if (exist.length == 0) {
          let model = {
            id: null,
            idPublic: null,
            measureType: measureType,
            value: null,
          };
          this.eventForm.values.push(model);
        }
      }
    },
    changeStartDate(newStartDate) {
      // move end date
      let diff = this.$dayjs(newStartDate).diff(this.oldStartDate);
      this.eventForm.endDate = this.$dayjs(this.eventForm.endDate)
        .add(diff, "millisecond")
        .format("YYYY-MM-DD");
    },
    // async loadItemsDropdown(eventType) {
    //   let response = null;
    //   switch (eventType) {
    //     case "DeliveryEvent":
    //       response = await CalendarApiService.getShippers();
    //       break;
    //     case "InstallationEvent":
    //       response = await CalendarApiService.getInstallers();
    //       break;
    //   }
    //   this.itemsList = response?.data.data;
    // },
    getEmptyEventForm() {
      return {
        id: null,
        title: null,
        eventType: null,
        isMainEvent: null,
        allDay: true,
        status: {
          id: null,
          name: null,
        },
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        shipper: null,
        installers: null,
        progress: null,
        comment: null,
        users: null,
        values: [],
        workOrder: {},
        userCreated: null,
        customHexColor: "",
      };
    },
    closeModal() {
      this.showModal = false;

      //empty lorsque le dialog nest plus visible
      setTimeout(() => {
        this.openValues = false;
        this.eventForm = this.getEmptyEventForm();
      }, 500);
    },
    async saveEvent() {
      this.eventForm.startDate = this.$dayjs(this.eventForm.startDate).format(
        "YYYY-MM-DD"
      );
      this.eventForm.endDate = this.$dayjs(this.eventForm.endDate).format(
        "YYYY-MM-DD"
      );

      await this.updateEventDetails(this.eventInfo, this.eventForm);
    },
    async setEventFormData(event) {
      //Type(progress, installation, delivery, production)
      this.eventForm.eventType = event.eventType;
      //load workOrder
      if (event.userCreated == false) {
        let workOrderResponse =
          await CalendarApiService.getWorkOrderByIdPublicNoWorkOrderSteps(
            event.workOrderIdPublic
          );
        this.eventForm.workOrder = workOrderResponse.data.data;

        if (this.eventForm.eventType == "StepEvent") {
          //load work order step values
          let workOrderStepValues =
            await CalendarApiService.getWorkOrderStepValues(
              event.workOrderStepIdPublic
            );

          this.eventForm.values = workOrderStepValues.data.data;
          this.setMissingValues();
          this.eventForm.status = event.status;
          this.eventForm.progress = this.$store.state.calendar.progress.find(
            (x) => event.stepIds.includes(x.stepIdPublic.toUpperCase())
          );
        }
      }

      this.eventForm.id = event.id;
      this.eventForm.title = event.title;
      this.eventForm.userCreated = event.userCreated;

      this.eventForm.customHexColor =
        event.customHexColor == null ? "" : event.customHexColor;

      this.eventForm.allDay = event.allDay;
      this.eventForm.isMainEvent = event.isMainEvent ?? false;

      this.eventForm.installers =
        this.eventForm.isMainEvent == false
          ? this.itemsList?.filter(
              (item) => event.installerIds?.includes(item.idPublic) ?? []
            ) ?? []
          : this.eventForm.workOrder.installers;
      this.eventForm.startDate = this.$dayjs(event.start).format("YYYY-MM-DD");
      this.eventForm.startTime = this.$dayjs(event.start).format("HH:mm");

      if (event.end) {
        this.eventForm.endDate = this.$dayjs(event.end).format("YYYY-MM-DD");
        this.eventForm.endTime = this.$dayjs(event.end).format("HH:mm");
      } else {
        this.eventForm.endDate = this.$dayjs(event.start).format("YYYY-MM-DD");
        this.eventForm.endTime = null;
      }

      this.eventForm.comment = event.comment;
      if (event.stepUserIds == null) {
        this.eventForm.users = [];
      } else {
        this.eventForm.users =
          JSON.parse(event.stepUserIds.toLowerCase().replace(/'/g, '"')) ?? [];
      }

      this.isLoadingInitial = false;
    },
    startEvent() {
      let startedStatus = this.statusList.filter(function (status) {
        return status == "Started";
      })[0];

      this.eventForm.status = startedStatus;
    },
    confirmBeforeComplete() {
      this.$refs.confirmModal.show().then((response) => {
        if (response == true) {
          this.completeEvent();
        }
      });
    },
    completeEvent() {
      //get progress config
      this.isLoading = true;
      let ev = this.eventForm;
      CalendarApiService.getProgressConfig(this.eventForm.progress.id).then(
        (response) => {
          this.isLoading = false;
          let config = response.data.data;
          if (
            config.askToCompletePreviousProgress == true &&
            ev.progress.id != ev.workOrder.nextProgressToComplete.id
          ) {
            this.$refs.completeStepsBeforeModal.show().then(
              (userResponse) => {
                this.completePreviousStepsOnComplete = userResponse;
                this.setEventComplete();
              },
              () => {}
            );
          } else {
            this.completePreviousStepsOnComplete = true;
            this.setEventComplete();
          }
        }
      );
    },
    setEventComplete() {
      let completedStatus = this.statusList.filter(function (status) {
        return status == "Completed";
      })[0];

      this.eventForm.status = completedStatus;

      //set start date to today
      var today = this.$dayjs().format("YYYY-MM-DD");

      var diff = this.$dayjs(this.eventForm.endDate).diff(
        this.$dayjs(this.eventForm.startDate),
        "day"
      );
      this.eventForm.startDate = today;
      this.eventForm.endDate = this.$dayjs(this.eventForm.startDate)
        .add(diff, "day")
        .format("YYYY-MM-DD");
    },
    toggleValues() {
      this.openValues = !this.openValues;
    },
    changeAllDay() {
      if (this.eventForm.allDay) {
        //update end time
        let newEndDate = this.$dayjs(this.eventForm.startDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
        this.eventForm.endDate = newEndDate;
        this.eventForm.endTime = null;
      }
    },
    updateColorProgress(color) {
      this.eventForm.customHexColor = color.hex;
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },
    getStartDateLabel() {
      let label = "";

      switch (this.eventForm.eventType) {
        case "StepEvent":
          label = this.$t("general.startDate");
          break;
        case "UserEvent":
          label = this.$t("general.startDate");
          break;
        case "DeliveryEvent":
          label = this.$t("general.deliveryDate");
          break;
        case "InstallationEvent":
          label = this.$t("general.installationDate");
          break;
        case "ProductionEvent":
          label = this.$t("general.productionDate");
          break;
      }

      return label;
    },
    async updateEventDetails(eventInfo, eventForm) {
      let userCreated = eventInfo.userCreated;

      if (userCreated) {
        this.updateUserAppointment(eventInfo, eventForm);
      } else if (
        eventForm.eventType == "InstallationEvent" ||
        eventForm.eventType == "DeliveryEvent" ||
        eventForm.eventType == "ProductionEvent"
      ) {
        this.updateReferenceAppointment(eventInfo, eventForm);
      } else if (eventForm.eventType == "StepEvent") {
        await this.updateProgressAppointment(eventInfo, eventForm);
      }
    },
    updateUserAppointment(eventInfo, eventForm) {
      let model = this.getAppointmentDtoModel(eventForm, eventInfo);
      this.isLoading = true;
      CalendarApiService.updateUserAppointmentDetails(model).then(() => {
        this.isLoading = false;
        this.closeModal();
        this.resolveFunction();
      });
    },
    async updateReferenceAppointment(eventInfo, eventForm) {
      let oldStart = eventInfo.start;
      let newStart = eventForm.startDate;
      let startDateChanged =
        this.$dayjs(oldStart).format("YYYY-MM-DD") !=
        this.$dayjs(newStart).format("YYYY-MM-DD");
      //Si update start date verifier si il existe des lien de progres brisés
      if (startDateChanged) {
        let borkenLinkResponse = await CalendarApiService.checkIfBrokenLink(
          eventInfo.workOrderIdPublic,
          eventForm.eventType
        );
        let brokenLinkExist = borkenLinkResponse.data.data;
        if (brokenLinkExist) {
          //fermer details modal avant douvrir lautre
          this.showModal = false;
          //show pop up demandant si on deplace les event avec un broken link
          this.$bus.$emit("open-confirm-move-broken-link-dialog", {
            callBack: (userChoice) => {
              if (userChoice != null) {
                this.showModal = true;
                this.updateReferenceAppointmentDetails(
                  eventInfo,
                  eventForm,
                  userChoice
                );
              }
            },
          });
        } else {
          //move reference appointment avec true ou false si on veut que les broken link suivent ou pas
          this.updateReferenceAppointmentDetails(eventInfo, eventForm, true);
        }
      } else {
        this.updateReferenceAppointmentDetails(eventInfo, eventForm, true);
      }
    },
    async updateProgressAppointment(eventInfo, eventForm) {
      let oldStart = eventInfo.start;
      let newStart = eventForm.startDate;
      // eslint-disable-next-line no-unused-vars
      let startDateChanged =
        this.$dayjs(oldStart).format("YYYY-MM-DD") !=
        this.$dayjs(newStart).format("YYYY-MM-DD");

      this.isLoading = true;

      let fixedString = eventInfo.stepIds.replace(/'/g, '"');
      let isMoveLater = (
        await CalendarApiService.getIsMoveLaterProgress(
          JSON.parse(fixedString)[0]
        )
      ).data.data;

      if (
        startDateChanged &&
        isMoveLater == true &&
        eventForm.status != "Completed"
      ) {
        let workOrderIdPublic = eventInfo.workOrderIdPublic;
        let response =
          await CalendarApiService.getLaterProgressAppointmentsV1_1(
            workOrderIdPublic,
            [eventForm.progress.stepIdPublic]
          );
        let laterProgressEvents = response.data.data;
        if (laterProgressEvents.length == 0) {
          this.updateProgressAppointmentDetails(eventInfo, eventForm);
        } else {
          //fermer details modal avant douvrir lautre
          this.showModal = false;
          this.$bus.$emit("open-move-progress-events-dialog", {
            events: laterProgressEvents,
            callBack: (selectedEvents) => {
              if (selectedEvents != null) {
                this.showModal = true;
                this.updateProgressAppointmentDetails(
                  eventInfo,
                  eventForm,
                  selectedEvents
                );
              }
            },
          });
        }
      } else {
        this.updateProgressAppointmentDetails(eventInfo, eventForm);
      }
    },
    updateProgressAppointmentDetails(event, eventForm, selectedEvents = []) {
      let model = this.getAppointmentDtoModel(eventForm, event);

      model.workOrderStepIdPublic = event.workOrderStepIdPublic;
      model.progress = eventForm.progress;
      model.userIds = eventForm.users;
      model.breakLinkToReferenceDate = event.breakLinkToReferenceDate;
      model.appointmentsToMove = selectedEvents;

      this.isLoading = true;
      CalendarApiService.updateProgressAppointmentDetails(model).then(() => {
        this.isLoading = false;
        this.closeModal();
        this.resolveFunction();
      });
    },
    updateReferenceAppointmentDetails(event, eventForm, moveBrokenLink) {
      let model = this.getAppointmentDtoModel(eventForm, event);
      model.moveBrokenLink = moveBrokenLink;
      model.type = event.eventType.replace("Event", "");
      this.isLoading = true;

      CalendarApiService.updateReferenceAppointmentDetails(model).then(() => {
        this.isLoading = false;
        this.closeModal();
        this.resolveFunction();
      });
    },
    getAppointmentDtoModel(eventForm, event) {
      eventForm.values.map((v) => {
        v.value = Number.isNaN(parseFloat(v.value))
          ? null
          : parseFloat(v.value);
      });
      let model = {
        id: event.id,
        title: eventForm.title,
        allDay: eventForm.allDay,
        isMainEvent: event.isMainEvent,
        installers: eventForm.installers,
        status: eventForm.status,
        startDate: eventForm.startDate,
        startTime: eventForm.startTime,
        endDate: eventForm.endDate,
        endTime: eventForm.endTime,
        comment: eventForm.comment,
        userCreated: event.userCreated,
        workOrder: eventForm.workOrder,
        customHexColor: eventForm.customHexColor,
        completePreviousProgressOnComplete:
          this.completePreviousStepsOnComplete,
        values: eventForm.values.filter((v) => {
          return !(v.id == null && Number.isNaN(parseFloat(v.value)));
        }),
      };

      return model;
    },
    sendEmail() {
      this.emailLoading = true;
      let model = {
        id: this.eventForm.id,
        title: this.eventForm.title,
        comment: this.eventForm.comment,
        allDay: this.eventForm.allDay,
        startDate: this.eventForm.startDate,
        endDate: this.eventForm.endDate,
        customHexColor: this.eventForm.customHexColor,
        workOrderIdPublic: this.eventForm.workOrder.idPublic,
        isMainEvent: this.eventForm.isMainEvent,
      };
      if (this.eventForm.eventType == "DeliveryEvent") {
        CalendarApiService.sendDeliveryEmail(model)
          .then(() => {
            this.closeModal();
          })
          .finally(() => {
            this.emailLoading = false;
          });
      }
      if (this.eventForm.eventType == "InstallationEvent") {
        CalendarApiService.sendInstallationEmail(model)
          .then(() => {
            this.closeModal();
          })
          .finally(() => {
            this.emailLoading = false;
          });
      }
    },
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.editEvent')"
    title-class="text-black font-18"
    scrollable
    body-class="p-3"
    id="eventDetailModal"
  >
    <form @submit.prevent="">
      <div class="row">
        <div class="col-12" style="position: relative">
          <loading
            :active.sync="isLoadingOverlay"
            :can-cancel="false"
            color="#f1b44c"
            :width="70"
            :opacity="0.5"
            :height="70"
            loader="dots"
            :is-full-page="false"
          ></loading>
          <div v-show="isLoadingInitial == false" class="row">
            <div class="col-12">
              <b-form-group class="font-weight-medium">
                <h3 v-if="eventForm.userCreated == false">
                  {{ eventForm.title }}
                </h3>
                <label v-if="eventForm.userCreated == true">{{
                  $t("general.title")
                }}</label>
                <b-form-input
                  v-if="eventForm.userCreated == true"
                  v-model="eventForm.title"
                  :placeholder="$t('general.title')"
                >
                </b-form-input>
                <a
                  v-if="eventForm.userCreated == false"
                  :href="googleMapLink"
                  target="_blank"
                  class="text-primary"
                  style="cursor: pointer"
                  ><i
                    style="font-size: 1.2em"
                    class="mr-1 mdi mdi-google-maps"
                  />{{ clientAddress }}</a
                >
                <a
                  v-if="eventForm.userCreated == false"
                  :href="`tel:${eventForm.workOrder.clientPhone}`"
                  target="_blank"
                  class="text-primary"
                  style="cursor: pointer"
                >
                  <i style="font-size: 1.2em" class="mr-1 mdi mdi-phone" />{{
                    eventForm.workOrder.clientPhone
                  }}
                </a>
                <div
                  id="workOrderInfo"
                  v-if="
                    eventForm.userCreated == false &&
                    eventForm.workOrder != null
                  "
                >
                  <label>{{ eventForm.workOrder.clientName }}</label>
                  <br />
                  <span>
                    <label v-if="eventForm.workOrder.template">{{
                      eventForm.workOrder.template.name
                    }}</label>
                    <label v-if="eventForm.workOrder.jobType"
                      >&nbsp;|&nbsp;{{
                        eventForm.workOrder.jobType.name
                      }}</label
                    >
                    <label v-if="eventForm.workOrder.workOrderGroup"
                      >&nbsp;|&nbsp;{{
                        eventForm.workOrder.workOrderGroup.name
                      }}</label
                    >
                  </span>
                  <div style="display: flex">
                    <p
                      v-if="
                        eventForm.workOrder.productionDate &&
                        userCanSeeProduction()
                      "
                      class="mb-1 mr-4"
                    >
                      {{ $t("general.productionDate") }}: <br /><span
                        class="text-muted"
                        >{{
                          $dayjs(eventForm.workOrder.productionDate).format("L")
                        }}</span
                      >&nbsp;&nbsp;
                    </p>

                    <p
                      v-if="
                        eventForm.workOrder.deliveryDate &&
                        userCanSeeAnyShipper()
                      "
                      class="mb-1 mr-4"
                    >
                      {{ $t("general.deliveryDate") }}: <br /><span
                        class="text-muted"
                        >{{
                          $dayjs(eventForm.workOrder.deliveryDate).format("L")
                        }}</span
                      >&nbsp;&nbsp;
                    </p>

                    <p
                      v-if="
                        eventForm.workOrder.installationDate &&
                        userCanSeeAnyInstaller()
                      "
                      class="mb-1"
                    >
                      {{ $t("general.installationDate") }}: <br /><span
                        class="text-muted"
                        >{{
                          $dayjs(eventForm.workOrder.installationDate).format(
                            "L"
                          )
                        }}</span
                      >
                    </p>
                  </div>
                </div>
              </b-form-group>
            </div>
            <div
              v-if="
                eventForm.eventType == 'StepEvent' &&
                eventForm.userCreated == false
              "
              class="col-12"
            >
              <div class="row">
                <div
                  class="col-12"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <h5>
                    {{ $t("general.progressValues") }}
                  </h5>
                  <i
                    @click="toggleValues()"
                    v-b-toggle.collapseValues
                    v-bind:class="{
                      'fas fa-angle-down': !openValues,
                      'fas fa-angle-up': openValues,
                    }"
                    style="font-size: 1.5em; cursor: pointer"
                  ></i>
                </div>
                <b-collapse class="col-12 mt-2" id="collapseValues">
                  <div class="row">
                    <div
                      class="col-6 col-md-4"
                      v-for="(progressValue, index) in eventForm.values"
                      :key="index"
                      id="collapseValues"
                      style="display: flex; align-items: flex-end"
                    >
                      <b-form-group style="position: relative">
                        <label>{{
                          $t("general." + progressValue.measureType)
                        }}</label>
                        <b-form-input
                          :disabled="
                            eventForm.workOrder.origin != 'Planification'
                          "
                          style="max-width: 120px"
                          type="number"
                          step="0.01"
                          v-model="progressValue.value"
                          placeholder="0.00"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <hr />
            </div>
            <h5
              class="col-12"
              v-if="
                eventForm.eventType === 'StepEvent' &&
                eventForm.progress !== null &&
                eventForm.userCreated === false
              "
            >
              {{ $t("general.progress") }}:
              <b-badge
                pill
                class="mb-2"
                :style="{ 'background-color': eventForm.progress.colorHexa }"
              >
                {{ eventForm.progress.name }}
              </b-badge>
            </h5>
            <div
              v-if="
                eventForm.eventType == 'StepEvent' &&
                eventForm.userCreated == false
              "
              class="col-12"
            >
              <b-form-group label="Status" class="font-weight-medium">
                <b-button
                  :disabled="isStarted || isCompleted"
                  @click="startEvent()"
                  variant="primary"
                  >{{ $t("general.start") }}</b-button
                >
                <b-button
                  :disabled="isCompleted"
                  @click="confirmBeforeComplete()"
                  variant="success"
                  class="ml-2"
                  >{{ $t("general.complete") }}</b-button
                >
                <i
                  v-show="isCompleted"
                  style="
                    color: #34c38f;
                    font-size: 2.5em;
                    vertical-align: middle;
                  "
                  class="bx bx-check-circle ml-2"
                ></i>
                <i
                  v-show="isStarted"
                  style="
                    color: #556ee6;
                    font-size: 1.8em;
                    vertical-align: middle;
                  "
                  class="mdi mdi-clock-start ml-2"
                ></i>
              </b-form-group>
            </div>

            <b-form-group
              class="col-12"
              v-if="eventForm.userCreated == true"
              style="position: relative"
            >
              <label>{{ $t("general.color") }}</label>
              <div class="mb-2" style="display: flex; align-items: center">
                <div
                  class="mr-2"
                  :style="{ background: eventForm.customHexColor }"
                  style="width: 27px; height: 27px; border-radius: 5px"
                ></div>
                <b-button
                  @click="toggleColorPicker()"
                  v-show="!showColorPicker"
                  size="sm"
                  variant="primary"
                  >{{ $t("general.selectColor") }}</b-button
                >
                <b-button
                  @click="toggleColorPicker()"
                  v-show="showColorPicker"
                  size="sm"
                  variant="primary"
                  >{{ $t("general.close") }}</b-button
                >
              </div>
              <Sketch
                v-show="showColorPicker"
                :value="eventForm.customHexColor"
                @input="updateColorProgress"
              />
            </b-form-group>

            <div
              v-if="
                eventForm.eventType != 'ProductionEvent' ||
                eventForm.userCreated == true
              "
              class="col-12"
            >
              <b-form-checkbox
                v-model="eventForm.allDay"
                @change="changeAllDay()"
                class="custom-checkbox mb-3 mt-2"
                >{{ $t("general.allDayEvent") }}</b-form-checkbox
              >
            </div>
            <div class="col-6">
              <b-form-group
                :label="getStartDateLabel()"
                class="font-weight-medium"
              >
                <date-picker
                  v-model="eventForm.startDate"
                  @focus="oldStartDate = eventForm.startDate"
                  @change="changeStartDate"
                  :first-day-of-week="1"
                  :format="this.$dayjs.localeData().longDateFormat('L')"
                  value-type="YYYY-MM-DD"
                  lang="en"
                >
                </date-picker>
              </b-form-group>

              <b-form-group
                v-if="
                  !eventForm.allDay &&
                  (eventForm.eventType != 'ProductionEvent' ||
                    eventForm.userCreated == true)
                "
                :label="$t('general.startTime')"
                class="font-weight-medium"
              >
                <date-picker
                  v-model="eventForm.startTime"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  placeholder="HH:mm"
                ></date-picker>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                v-if="
                  eventForm.eventType != 'ProductionEvent' ||
                  eventForm.userCreated == true
                "
                :label="$t('general.endDate')"
                class="font-weight-medium"
              >
                <date-picker
                  v-model="eventForm.endDate"
                  :first-day-of-week="1"
                  :format="this.$dayjs.localeData().longDateFormat('L')"
                  value-type="YYYY-MM-DD"
                  lang="en"
                ></date-picker>
              </b-form-group>
              <b-form-group
                v-if="
                  !eventForm.allDay &&
                  (eventForm.eventType != 'ProductionEvent' ||
                    eventForm.userCreated == true)
                "
                :label="$t('general.endTime')"
                class="font-weight-medium"
              >
                <date-picker
                  v-model="eventForm.endTime"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  placeholder="HH:mm"
                ></date-picker>
              </b-form-group>
            </div>
          </div>

          <b-form-group
            v-if="
              eventForm.eventType == 'DeliveryEvent' &&
              eventForm.userCreated == false
            "
            class="font-weight-medium"
            :label="$t('general.shipper')"
          >
            <multiselect
              :placeholder="$t('general.shipper')"
              v-model="eventForm.workOrder.shipper"
              :options="itemsList"
              :show-labels="false"
              label="name"
              track-by="id"
            ></multiselect>
          </b-form-group>

          <b-form-group
            v-if="
              eventForm.eventType == 'InstallationEvent' &&
              eventForm.userCreated == false
            "
            class="font-weight-medium"
            :label="$t('general.installer')"
          >
            <multiselect
              v-if="eventForm.isMainEvent"
              :multiple="true"
              :placeholder="$t('general.installers')"
              v-model="eventForm.workOrder.installers"
              :options="itemsList"
              :show-labels="false"
              label="name"
              track-by="id"
            ></multiselect>
            <multiselect
              v-else
              :multiple="true"
              :placeholder="$t('general.installers')"
              v-model="eventForm.installers"
              :options="itemsList"
              :show-labels="false"
              label="name"
              track-by="id"
            ></multiselect>
          </b-form-group>

          <b-form-group
            class="font-weight-medium"
            v-if="
              eventForm.eventType == 'StepEvent' &&
              eventForm.progress != null &&
              eventForm.progress.users != null &&
              eventForm.progress.users.length > 0
            "
            :label="$t('general.users')"
          >
            <UsersMultiSelect
              v-model="eventForm.users"
              :users="eventForm.progress.users"
              trackBy="id"
              label="displayName"
            />

            <!-- {{ eventForm.progress.users }} -->
            <!-- {{ eventForm.users }} -->
            <!-- <multiselect
              v-model="editEvent.users"
              :options="usersList"
              multiple
              :placeholder="$t('general.assignedUsers')"
              label="text"
              track-by="value"
            >
            </multiselect> -->
          </b-form-group>
          <b-form-group
            class="font-weight-medium"
            :label="$t('general.comment')"
          >
            <textarea
              v-model="eventForm.comment"
              class="form-control"
              rows="3"
              :placeholder="$t('general.comment')"
            ></textarea>
          </b-form-group>
          <b-form-group
            v-if="eventForm.userCreated == false"
            class="font-weight-medium"
            :label="$t('general.workOrderComment')"
          >
            <textarea
              v-model="eventForm.workOrder.comment"
              class="form-control"
              rows="3"
              :placeholder="$t('general.workOrderComment')"
            ></textarea>
          </b-form-group>
          <documentation-component
            :options="documentation_options"
            v-model="documentation_event"
            v-show="userCanSeeAnyDocumentation"
          />
        </div>
      </div>
    </form>
    <template #modal-footer>
      <div class="d-flex flex-row w-100">
        <div v-if="resourceHasEmail">
          <div
            v-if="
              eventForm.eventType == 'InstallationEvent' ||
              eventForm.eventType == 'DeliveryEvent'
            "
          >
            <b-button
              @click="sendEmail"
              class="d-flex align-items-center"
              variant="success"
            >
              {{ $t("general.sendEmail") }}
              <span
                v-if="emailLoading"
                class="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            </b-button>
          </div>
        </div>
        <div class="ml-auto">
          <b-button variant="light" @click="closeModal">
            {{ $t("general.cancel") }}
          </b-button>
          <b-button @click="saveEvent()" class="ml-1" variant="success">
            {{ $t("general.save") }}
          </b-button>
        </div>
      </div>
    </template>

    <CompleteStepsBeforeModal
      ref="completeStepsBeforeModal"
    ></CompleteStepsBeforeModal>
    <ConfirmModal ref="confirmModal" />
  </b-modal>
</template>