<script>
import UserConfigMixin from "@/mixins/userConfigMixin";
export default {
  components: {},
  mixins: [UserConfigMixin],
  model: {
    prop: "selectedSources",
    event: "change",
  },
  props: ["selectedSources", "options", "isLoading"],
  data() {
    return {
      configuration: {
        steps: {
          containerOpen: false,
          selectAll: false,
        },
        installers: {
          containerOpen: false,
          selectAll: false,
        },
        shippers: {
          containerOpen: false,
          selectAll: false,
        },
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    configureStepSources(steps) {
      let selectedProgressArray =
        localStorage
          .getItem(process.env.NODE_ENV + ".selected_progress")
          ?.split(",") ?? [];
      let oldLocalStorage = selectedProgressArray.length !== 0;

      if (oldLocalStorage) {
        let sources = steps.filter((x) =>
          selectedProgressArray.includes(String(x.id))
        );
        this.selectedSources.steps.push(
          ...sources.map((x) => {
            return {
              id: x.stepIdPublic,
              title: x.name,
              children: x.users.map((u) => {
                return {
                  id:
                    u.id === "00000000-0000-0000-0000-000000000000"
                      ? x.stepIdPublic + "." + u.id
                      : u.id,
                  resourceType: "ProgressUser",
                  userId: u.userId,
                  title:
                    u.id === "00000000-0000-0000-0000-000000000000"
                      ? this.$t("general.unassigned")
                      : u.displayName,
                };
              }),
              resourceType: "StepEvent",
              resourceTypeOrder: 1,
              color: x.colorHexa,
              order: x.order,
            };
          })
        );
        localStorage.removeItem(process.env.NODE_ENV + ".selected_progress");
      } else {
        var selectedSources = JSON.parse(
          localStorage.getItem("selectedSources")
        );
        if (selectedSources) {
          this.selectedSources.steps.push(...selectedSources.steps.filter(x => this.userCanSeeProgressId(x.id)));
        }
      }

      this.options.sources.steps = steps.map((x) => {
        let checked =
          oldLocalStorage === true
            ? selectedProgressArray?.includes(String(x.id)) ?? false
            : this.selectedSources.steps.find(
                (s) => x.stepIdPublic === s.id
              ) !== undefined;
        let selectedChildren =
          oldLocalStorage === true
            ? checked && x.users !== undefined
              ? x.users.map((u) =>
                  u.id === "00000000-0000-0000-0000-000000000000"
                    ? x.stepIdPublic + "." + u.id
                    : u.id
                )
              : []
            : this.selectedSources.steps
                .find(
                  (s) =>
                    x.stepIdPublic === s.id ||
                    s.id ===
                      x.stepIdPublic + ".00000000-0000-0000-0000-000000000000"
                )
                ?.children.map((x) => {
                  return x.id;
                }) ?? [];
        let step = {
          id: x.stepIdPublic,
          title: x.name,
          children: x.users.map((u) => {
            return {
              id:
                u.id === "00000000-0000-0000-0000-000000000000"
                  ? x.stepIdPublic + "." + u.id
                  : u.id,
              resourceType: "ProgressUser",
              userId: u.userId,
              title:
                u.id === "00000000-0000-0000-0000-000000000000"
                  ? this.$t("general.unassigned")
                  : u.displayName,
            };
          }),
          color: x.colorHexa,
          selectedChildren: selectedChildren,
          checked: checked,
          toggleChildren: false,
          indeterminate: checked && selectedChildren.length !== x.users.length,
          allUserSelected: false,
          resourceType: "StepEvent",
          resourceTypeOrder: 1,
          order: x.order,
        };
        return step;
      });
    },
    configureShipperSources(shippers) {
      let selectedShippersArray =
        localStorage
          .getItem(process.env.NODE_ENV + ".selected_shippers")
          ?.split(",") ?? [];
      let oldLocalStorage = selectedShippersArray.length !== 0;

      if (oldLocalStorage) {
        let sources = shippers.filter((x) =>
          selectedShippersArray.includes(String(x.id))
        );
        this.selectedSources.shippers.push(
          ...sources.map((x) => {
            return {
              id: x.idPublic,
              title: x.name,
              children: [],
              resourceType: "DeliveryEvent",
              resourceTypeOrder: 2,
              color: x.colorHexa,
              order: x.order,
            };
          })
        );
        if (userCanSeeUnassigned && selectedShippersArray.includes("0")) {
          this.selectedSources.shippers.unshift({
            id: "DeliveryEvent.00000000-0000-0000-0000-000000000000",
            title: this.$t("general.unassigned"),
            children: [],
            resourceType: "DeliveryEvent",
            resourceTypeOrder: 2,
            order: 0,
          });
        }
        localStorage.removeItem(process.env.NODE_ENV + ".selected_shippers");
      } else {
        var selectedSources = JSON.parse(
          localStorage.getItem("selectedSources")
        );
        if (selectedSources) {
          this.selectedSources.shippers.push(...selectedSources.shippers.filter(x => this.userCanSeeShipperId(x.id)));
        }
      }
      this.options.sources.shippers = shippers.map((x) => {
        let isSelected =
          oldLocalStorage === true
            ? selectedShippersArray?.includes(String(x.id)) ?? false
            : this.selectedSources.shippers.find((s) => x.idPublic === s.id) !==
              undefined;
        return {
          id: x.idPublic,
          title: x.name,
          children: [],
          color: x.colorHexa,
          selectedChildren: [],
          checked: isSelected,
          resourceType: "DeliveryEvent",
          resourceTypeOrder: 2,
          order: x.order,
        };
      });
      let isSelectedUnassigned =
        oldLocalStorage === true
          ? selectedShippersArray?.includes(String("0")) ?? false
          : this.selectedSources.shippers.find(
              (s) =>
                "DeliveryEvent.00000000-0000-0000-0000-000000000000" === s.id
            ) !== undefined;
      let userCanSeeUnassigned = this.userCanSeeShipper({
        id: 0,
        idPublic: "00000000-0000-0000-0000-000000000000",
      });
      this.options.sources.shippers.unshift({
        id: "DeliveryEvent.00000000-0000-0000-0000-000000000000",
        title: this.$t("general.unassigned"),
        children: [],
        selectedChildren: [],
        checked: isSelectedUnassigned && userCanSeeUnassigned,
        resourceType: "DeliveryEvent",
        resourceTypeOrder: 2,
        order: 0,
      });
    },
    configureInstallerSources(installers) {
      let selectedInstallersArray =
        localStorage
          .getItem(process.env.NODE_ENV + ".selected_installers")
          ?.split(",") ?? [];

      let oldLocalStorage = selectedInstallersArray.length !== 0;
      if (oldLocalStorage) {
        let sources = installers.filter((x) =>
          selectedInstallersArray.includes(String(x.id))
        );
        this.selectedSources.installers.push(
          ...sources.map((x) => {
            return {
              id: x.idPublic,
              title: x.name,
              children: [],
              resourceType: "InstallationEvent",
              resourceTypeOrder: 3,
              color: x.colorHexa,
              order: x.order,
            };
          })
        );
        if (userCanSeeUnassigned && selectedInstallersArray.includes("0")) {
          this.selectedSources.installers.unshift({
            id: "InstallationEvent.00000000-0000-0000-0000-000000000000",
            title: this.$t("general.unassigned"),
            children: [],
            resourceType: "InstallationEvent",
            resourceTypeOrder: 3,
            order: 0,
          });
        }
        localStorage.removeItem(process.env.NODE_ENV + ".selected_installers");
      } else {
        var selectedSources = JSON.parse(
          localStorage.getItem("selectedSources")
        );
        if (selectedSources) {
          this.selectedSources.installers.push(...selectedSources.installers.filter(x => this.userCanSeeInstallerId(x.id)));
        }
      }

      this.options.sources.installers = installers.map((x) => {
        let isSelected =
          oldLocalStorage === true
            ? selectedInstallersArray?.includes(String(x.id)) ?? false
            : this.selectedSources.installers.find(
                (s) => x.idPublic === s.id
              ) !== undefined;
        return {
          id: x.idPublic,
          title: x.name,
          children: [],
          color: x.colorHexa,
          selectedChildren: [],
          checked: isSelected,
          resourceType: "InstallationEvent",
          resourceTypeOrder: 3,
          order: x.order,
        };
      });
      let isSelectedUnassigned =
        oldLocalStorage === true
          ? selectedInstallersArray?.includes(String("0")) ?? false
          : this.selectedSources.installers.find(
              (s) =>
                "InstallationEvent.00000000-0000-0000-0000-000000000000" ===
                s.id
            ) !== undefined;
      let userCanSeeUnassigned = this.userCanSeeInstaller({
        id: 0,
        idPublic: "00000000-0000-0000-0000-000000000000",
      });
      this.options.sources.installers.unshift({
        id: "InstallationEvent.00000000-0000-0000-0000-000000000000",
        title: this.$t("general.unassigned"),
        children: [],
        selectedChildren: [],
        checked: isSelectedUnassigned && userCanSeeUnassigned,
        resourceType: "InstallationEvent",
        resourceTypeOrder: 3,
        order: 0,
      });
    },
    configureProductionSource() {
      let oldLocalStorage =
        localStorage.getItem(process.env.NODE_ENV + ".production_checked") !==
        null;
      if (oldLocalStorage) {
        this.options.sources.production = localStorage.getItem(
          process.env.NODE_ENV + ".production_checked"
        );
        localStorage.removeItem(process.env.NODE_ENV + ".production_checked");
      } else {
        var selectedSources = JSON.parse(
          localStorage.getItem("selectedSources")
        );
        if (selectedSources && selectedSources.production.length !== 0) {
          this.options.sources.production = true;
          this.selectedSources.production.push({
            id: "ProductionEvent.00000000-0000-0000-0000-000000000000",
            title: this.$t("general.production"),
            resourceType: "ProductionEvent",
            resourceTypeOrder: 4,
            order: 0,
          });
        }
      }
    },
    toggleContainer(sourceType) {
      this.configuration[sourceType].containerOpen =
        !this.configuration[sourceType].containerOpen;
    },
    changeProductionCheckbox() {
      if (this.options.sources.production === true) {
        this.selectedSources.production.push({
          id: "ProductionEvent.00000000-0000-0000-0000-000000000000",
          title: this.$t("general.production"),
          resourceType: "ProductionEvent",
          resourceTypeOrder: 4,
          order: 0,
        });
      } else {
        this.selectedSources.production.pop();
      }
      localStorage.setItem(
        "selectedSources",
        JSON.stringify(this.selectedSources)
      );
      this.$emit("change", this.selectedSources);
    },
    changeAllCheckbox(sourceType) {
      var sources = this.options.sources[sourceType].filter(
        (x) => x.checked !== this.configuration[sourceType].selectAll
      );
      for (let p of sources) {
        p.checked = this.configuration[sourceType].selectAll;
        p.indeterminate = false;
        p.selectedChildren = p.checked
          ? p.children.map((child) => child.id).slice()
          : [];
      }
      if (this.configuration[sourceType].selectAll === true) {
        this.selectedSources[sourceType].push(
          ...sources.map((x) => {
            return {
              id: x.id,
              title: x.title,
              children: x.children,
              resourceType: x.resourceType,
              resourceTypeOrder: x.resourceTypeOrder,
              color: x.color,
              order: x.order,
            };
          })
        );
      } else {
        sources.forEach((source) => {
          this.selectedSources[sourceType].splice(
            this.selectedSources[sourceType].indexOf(
              this.selectedSources[sourceType].find((x) => x.id === source.id)
            ),
            1
          );
        });
      }
      localStorage.setItem(
        "selectedSources",
        JSON.stringify(this.selectedSources)
      );
      this.$emit("change", this.selectedSources);
    },
    changeCheckbox(sourceType, source) {
      source.selectedChildren = source.checked
        ? source.children.map((child) => child.id).slice()
        : [];
      if (source.checked === true) {
        this.selectedSources[sourceType].push({
          id: source.id,
          title: source.title,
          children: source.children.filter((x) =>
            source.selectedChildren.includes(x.id)
          ),
          resourceType: source.resourceType,
          resourceTypeOrder: source.resourceTypeOrder,
          color: source.color,
          order: source.order,
        });
      } else {
        this.selectedSources[sourceType].splice(
          this.selectedSources[sourceType].indexOf(
            this.selectedSources[sourceType].find((x) => x.id === source.id)
          ),
          1
        );
      }
      localStorage.setItem(
        "selectedSources",
        JSON.stringify(this.selectedSources)
      );
      this.$emit("change", this.selectedSources);
    },
    changeChildrenSelection(sourceType, prog) {
      if (
        prog.selectedChildren.length > 0 &&
        prog.selectedChildren.length != prog.children.length
      ) {
        prog.indeterminate = true;
      } else if (prog.selectedChildren.length == prog.children.length) {
        prog.indeterminate = false;
        prog.checked = true;
      } else if (prog.selectedChildren.length == 0) {
        prog.indeterminate = false;
        prog.checked = false;
      }
      var parentSource = this.selectedSources[sourceType].find(
        (x) => x.id === prog.id
      );
      if (parentSource === undefined) {
        this.selectedSources[sourceType].push({
          id: prog.id,
          title: prog.title,
          children: prog.children.filter((c) =>
            prog.selectedChildren.includes(c.id)
          ),
          resourceType: prog.resourceType,
          resourceTypeOrder: prog.resourceTypeOrder,
          color: prog.color,
          order: prog.order,
        });
      } else {
        parentSource.children = prog.children.filter((x) =>
          prog.selectedChildren.includes(x.id)
        );
      }
      localStorage.setItem(
        "selectedSources",
        JSON.stringify(this.selectedSources)
      );
      this.$emit("change", this.selectedSources);
    },
  },
  mounted() {},
};
</script>
<template>
  <div>
    <div
      class="mb-2 agendaContainer"
      id="stepsContainer"
      v-if="userCanSeeAnyProgress()"
    >
      <b-button
        class="mr-2"
        @click="toggleContainer('steps')"
        :class="{
          agendaTypeBtn: !configuration.steps.containerOpen,
          selectedAgendaTypeBtn: configuration.steps.containerOpen,
        }"
        style="
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        "
        v-b-toggle="'collapse_steps'"
        ><label class="mb-0">{{ $t("general.progress") }}</label
        ><i
          v-bind:class="{
            'fas fa-angle-down': !configuration.steps.containerOpen,
            'fas fa-angle-up': configuration.steps.containerOpen,
          }"
        ></i
      ></b-button>
      <b-collapse id="collapse_steps" class="mt-1">
        <div class="mb-0 agendaSubContainer">
          <loading
            :active.sync="isLoading.steps"
            :can-cancel="false"
            color="#f1b44c"
            :width="70"
            :height="70"
            loader="dots"
            :is-full-page="false"
          ></loading>
          <ul style="list-style-type: none; padding-left: 0; margin-bottom: 0">
            <li>
              <div class="d-flex flex-row">
                <div
                  style="width: 16px"
                  v-if="isTaskManagementOrganisationUser"
                ></div>
                <b-form-checkbox
                  v-model="configuration.steps.selectAll"
                  class="custom-checkbox mb-3"
                  @change="changeAllCheckbox('steps')"
                  >{{ $t("general.all") }}</b-form-checkbox
                >
              </div>
            </li>
            <li
              style="
                display: flex;
                flex-direction: column;
                width: '100%';
                justify-content: space-between;
              "
              v-for="prog in options.sources.steps"
              :key="prog.id"
            >
              <div class="d-flex flex-row">
                <div
                  style="width: 16px"
                  v-if="isTaskManagementOrganisationUser"
                >
                  <i
                    v-if="prog.children.length > 0"
                    @click="prog.toggleChildren = !prog.toggleChildren"
                    style="cursor: pointer"
                    v-bind:class="{
                      'fas fa-angle-down': !prog.toggleChildren,
                      'fas fa-angle-up': prog.toggleChildren,
                    }"
                    class="w-100 font-weight-bold"
                  />
                </div>
                <!-- intermediate state here -->
                <b-form-checkbox
                  v-model="prog.checked"
                  :indeterminate="prog.indeterminate"
                  class="custom-checkbox mb-3"
                  @change="changeCheckbox('steps', prog)"
                  :aria-controls="prog.id"
                  :aria-describedby="prog.id"
                >
                  <div
                    @click="
                      () =>
                        (prog.toggleChildren =
                          prog.children.length > 0 ? true : false)
                    "
                  >
                    <span
                      :class="{ 'font-weight-bold': prog.children.length > 0 }"
                      >{{ prog.title }}</span
                    ><i
                      class="bx bx-user-circle ml-1 font-weight-bold align-middle font-size-14"
                      v-if="prog.children.length > 0"
                    ></i>
                  </div>
                </b-form-checkbox>

                <div
                  :v-b-toggle="prog.id"
                  style="display: flex; flex-direction: row"
                  class="ml-auto"
                >
                  <div
                    :style="{ 'background-color': prog.color }"
                    style="width: 10px; margin-left: auto"
                    class="color-box p-1 mb-3 rounded"
                  ></div>
                </div>
              </div>

              <b-collapse
                v-model="prog.toggleChildren"
                class="mb-3 pl-4 rounded"
                v-if="prog.children.length > 0"
              >
                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    :id="prog.id"
                    :name="prog.id"
                    class="progress-user-checkbox"
                    :options="prog.children"
                    v-model="prog.selectedChildren"
                    value-field="id"
                    text-field="title"
                    :aria-describedby="ariaDescribedby"
                    stacked
                    @change="changeChildrenSelection('steps', prog)"
                  >
                  </b-form-checkbox-group>
                </template>
                <hr class="mt-1" />
              </b-collapse>
            </li>
          </ul>
        </div>
      </b-collapse>
    </div>
    <div
      id="deliveryContainer"
      class="mb-2 agendaContainer"
      v-if="userCanSeeAnyShipper()"
    >
      <b-button
        class="mr-2 agendaTypeBtn"
        @click="toggleContainer('shippers')"
        :class="{
          agendaTypeBtn: !configuration.shippers.containerOpen,
          selectedAgendaTypeBtn: configuration.shippers.containerOpen,
        }"
        style="
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        "
        v-b-toggle="'collapseDelivery'"
        ><label class="mb-0">{{ $t("general.delivery") }}</label
        ><i
          v-bind:class="{
            'fas fa-angle-down': !configuration.shippers.containerOpen,
            'fas fa-angle-up': configuration.shippers.containerOpen,
          }"
        ></i
      ></b-button>
      <b-collapse id="collapseDelivery" class="mt-1">
        <div class="mb-0 agendaSubContainer">
          <loading
            :active.sync="isLoading.shippers"
            :can-cancel="false"
            color="#f1b44c"
            :width="70"
            :height="70"
            loader="dots"
            :is-full-page="false"
          ></loading>
          <ul style="list-style-type: none; padding-left: 0">
            <li>
              <b-form-checkbox
                v-model="configuration.shippers.selectAll"
                class="custom-checkbox mb-3"
                @change="changeAllCheckbox('shippers')"
                checked
                >{{ $t("general.all") }}</b-form-checkbox
              >
            </li>
            <li
              v-for="shipper in options.sources.shippers"
              :key="shipper.idPublic"
            >
              <b-form-checkbox
                v-model="shipper.checked"
                class="custom-checkbox mb-3"
                @change="changeCheckbox('shippers', shipper)"
                checked
                >{{
                  shipper.id == 0 ? $t("general.unassigned") : shipper.title
                }}</b-form-checkbox
              >
            </li>
          </ul>
        </div>
      </b-collapse>
    </div>

    <div
      id="installationContainer"
      class="mb-2 agendaContainer"
      v-if="userCanSeeAnyInstaller()"
    >
      <b-button
        class="mr-2 agendaTypeBtn"
        @click="toggleContainer('installers')"
        :class="{
          agendaTypeBtn: !configuration.installers.containerOpen,
          selectedAgendaTypeBtn: configuration.installers.containerOpen,
        }"
        style="
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        "
        v-b-toggle="'collapseInstallation'"
        ><label class="mb-0"
          ><label class="mb-0">{{ $t("general.installation") }}</label></label
        ><i
          v-bind:class="{
            'fas fa-angle-down': !configuration.installers.containerOpen,
            'fas fa-angle-up': configuration.installers.containerOpen,
          }"
        ></i
      ></b-button>
      <b-collapse id="collapseInstallation" class="mt-1">
        <div class="mb-0 agendaSubContainer">
          <loading
            :active.sync="isLoading.installers"
            :can-cancel="false"
            color="#f1b44c"
            :width="70"
            :height="70"
            loader="dots"
            :is-full-page="false"
          ></loading>
          <ul style="list-style-type: none; padding-left: 0">
            <li>
              <b-form-checkbox
                v-model="configuration.installers.selectAll"
                @change="changeAllCheckbox('installers')"
                class="custom-checkbox mb-3"
                checked
                >{{ $t("general.all") }}</b-form-checkbox
              >
            </li>
            <li
              v-for="installer in options.sources.installers"
              :key="installer.idPublic"
            >
              <b-form-checkbox
                v-model="installer.checked"
                class="custom-checkbox mb-3"
                @change="changeCheckbox('installers', installer)"
                checked
                >{{
                  installer.id == 0 ? $t("general.unassigned") : installer.title
                }}</b-form-checkbox
              >
            </li>
          </ul>
        </div>
      </b-collapse>
    </div>

    <div
      v-if="userCanSeeProduction()"
      id="productionContainer"
      class="mb-3 agendaContainer"
    >
      <b-button
        class="mr-2 agendaTypeBtn"
        :class="{
          agendaTypeBtn: !options.sources.production,
          selectedAgendaTypeBtn: options.sources.production,
        }"
        style="
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        "
      >
        <label class="mb-0">{{ $t("general.production") }}</label>
        <b-form-checkbox
          v-model="options.sources.production"
          @change="changeProductionCheckbox"
        >
        </b-form-checkbox>
      </b-button>
    </div>
  </div>
</template>