
<script>



export default {
  components: {

  },
  data() {
    return {
      showModal: false,
      callBack: null,
      events: [],
      selectionToggle: true
    };
  },
  created(){
    this.$bus.$on('open-move-progress-events-dialog', this.openMoveProgressEventsDialog);
  },
  beforeDestroy() {
    this.$bus.$off('open-move-progress-events-dialog', this.openMoveProgressEventsDialog);
  },   
  computed: {

  },
  methods: {
    openMoveProgressEventsDialog: function(data){
      this.callBack = data.callBack;
      this.events = data.events;
      this.selectionToggle = true;
      //set checked property
      for(let ev of this.events){
        this.$set(ev, 'checked', true)
      }

      this.showModal = true;
    },
    move(){
      let selectedEvents = this.events.filter(e => {
        return e.checked == true;
      });
      this.showModal = false;
      this.callBack(selectedEvents);
    },
    cancel(){
      this.showModal = false;
      this.callBack(null);
    },
    toggleSelection(checked){
      this.selectionToggle = checked;
      for(var ev of this.events){
        ev.checked = checked;
      }
    }
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.selectEventsToMoveTitle')"
    title-class="text-black font-18"
    scrollable
    body-class="p-3"
    id="selectEventsToMoveModal"
    no-close-on-backdrop
  >
    <div class="row">
      <div class="col-12">
        <p>{{$t('general.selectEventsToMove')}}</p> 
      </div>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead class="thead-light">
              <tr>
                <th>{{$t('general.progress')}}</th>
                <th style="width:100px; text-align:center;">{{$t('general.link')}}</th>
                <th style="width:100px; text-align:center; align-items:center;">
                  <div style="display:flex; position:relative;">
                    <span style="margin-right:35px;">{{$t('general.move')}}</span>
                    <i @click="toggleSelection(!selectionToggle)" style="position:absolute; top:-3px; right:3px; cursor:pointer; font-size:2em;" class="bx bx-toggle-left"></i>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(event, index) in events" :key="index" >
                <td style="vertical-align:middle;">
                  <div class="mr-3" style="display:inline-block; vertical-align: middle; width:20px; height:20px; border-radius:100px;" :style="{ background: event.progress.colorHexa}"></div><span style="vertical-align: middle;">{{event.progress.name}}</span>
                </td>
                <td style="vertical-align:middle; text-align:center;">
                  <i v-if="event.breakLinkToReferenceDate == true" style="font-size:1.2em;" class="mdi mdi-link-variant-off"></i>
                </td>
                <td style="text-align:center;">
                  <b-form-checkbox
                    v-model="event.checked"
                    class="custom-checkbox"
                  ></b-form-checkbox>                            
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>      
    </div>
    <template #modal-footer class="text-right pt-3">
      <b-button @click="cancel()" class="ml-1">{{$t('general.cancel')}}</b-button>
      <b-button @click="move()" class="ml-1" variant="primary">{{$t('general.move')}}</b-button>
    </template>
  </b-modal>
</template>