<script>
import EventService from "@/services/event-service";
import UserConfigMixin from "@/mixins/userConfigMixin";
export default {
  mixins: [UserConfigMixin],
  model: {
    prop: "event",
    event: "input",
  },
  props: ["event", "view", "fields", "mobileScreen"],
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
  computed: {
    hasExtendedProps() {
      if (Object.keys(this.event.extendedProps).length === 0) {
        return false;
      }
      return true;
    },
    displayOverlay() {
      return (
        this.$dayjs(this.event.start).isBefore(this.$dayjs()) && this.completed
      );
    },
    completed() {
      return this.event.extendedProps.eventType == "StepEvent" &&
        this.event.extendedProps.userCreated == false
        ? this.event.extendedProps.status == "Completed"
        : false;
    },
    started() {
      return this.event.extendedProps.eventType == "StepEvent" &&
        this.event.extendedProps.userCreated == false
        ? this.event.extendedProps.status == "Started"
        : false;
    },
    daysBeforeReferenceDate() {
      if (
        this.event.extendedProps.eventType != "StepEvent" ||
        this.event.extendedProps.userCreated
      ) {
        return 0;
      }

      let numberOfDays = EventService.getNumberOfDaysUntilReference(
        this.event,
        this.view.calendar.getEvents()
        .filter(
          (x) =>
            x.extendedProps.eventType == "BlockerEvent" && x.extendedProps.selectedProgress != null && x.extendedProps.selectedProgress.includes(this.steps[0].id)
        ),
        this.userConfig.calendarBackschedulingCalculation,
        this.steps[0]
      );

      return numberOfDays;
    },
    pillColor() {
      if (this.event.extendedProps.customHexColor != null) {
        return this.event.extendedProps.customHexColor;
      }
      let color = "white";
      if (!this.event.extendedProps.userCreated) {
        if (
          (this.event.extendedProps.eventType == "DeliveryEvent" ||
            this.event.extendedProps.eventType == "InstallationEvent" ||
            this.event.extendedProps.eventType == "ProductionEvent") &&
          this.lastProgressCompleted != null
        ) {
          color =
            this.lastProgressCompleted.colorHexa;
        } else if (this.event.extendedProps.eventType == "StepEvent") {
          color = this.steps[0].colorHexa;
        }
      }
      return color;
    },
    info() {
      let info = {
        border: "1px solid transparent",
        backgroundColor: "white",
        fontColor: "white",
      };
      if (this.event.extendedProps.eventType == 'BlockerEvent') {
        info.backgroundColor = this.event.extendedProps.customHexColor;
        info.fontColor = this.event.textColor;
      }
      if (this.event.extendedProps.userCreated == false) {
        if (
          this.event.extendedProps.eventType == "DeliveryEvent" ||
          this.event.extendedProps.eventType == "InstallationEvent" ||
          this.event.extendedProps.eventType == "ProductionEvent"
        ) {
          info.border = "none";
          if (!this.event.extendedProps.workOrderIsStarted) {
            info.backgroundColor = "#5C5A5C";
          } else if (
            this.event.extendedProps.workOrderIsStarted &&
            this.event.extendedProps.workOrderPercentCompleted != 1
          ) {
            info.backgroundColor = "#FFE523";
            info.border = "1px solid #FFE523";
            info.fontColor = "#59542F";
          } else if (this.event.extendedProps.workOrderPercentCompleted == 1) {
            info.backgroundColor = "white";
            info.border = "1px solid #34c38f";
            info.fontColor = "#34c38f";
          }
        } else if (this.event.extendedProps.eventType == "StepEvent") {
          let readyToStart =
            this.steps[0].stepIdPublic ==
            this.nextProgressToComplete
              ?.stepIdPublic;
          if (this.userConfig.calendarBackschedulingCalculation == "currentDate") {
            info.fontColor = "white"
          } else {
            info.fontColor = this.completed || !readyToStart   ? "white" : "#59542F";
          }
          info.backgroundColor =
            this.completed || this.started
              ? "white"
              : readyToStart && this.userConfig.calendarBackschedulingCalculation != "currentDate"
                ? "#FFE523"
                : "#5C5A5C";
          info.border = this.completed
            ? "1px solid #34c38f"
            : this.started
              ? "1px solid #556ee6"
              : "1px solid transparent";
        }
      }
      return info;
    },
    lastProgressCompleted() {
      return this.$store.state.calendar.progress.find(x => x.stepIdPublic === this.event.extendedProps.workOrderLastCompletedStepIdPublic)
    },
    nextProgressToComplete() {
      return this.$store.state.calendar.progress.find(x => x.stepIdPublic === this.event.extendedProps.workOrderNextProgressToCompleteStepIdPublic)
    },
    installers() {
      if(this.event.extendedProps.installerIds === null) {
        return [];
      }
      return this.$store.state.calendar.installers.filter(x => this.event.extendedProps.installerIds.includes(x.idPublic));    
    },
    shipperCodes() {
      if(this.event.extendedProps.shipperIds === null || this.event.extendedProps.eventType !== 'DeliveryEvent') {
        return null;
      }
      return this.$store.state.calendar.shippers.filter(x => this.event.extendedProps.shipperIds.includes(x.idPublic)).map(x => x.code);    
    },
    users() {
      if(this.event.extendedProps.stepUserIds === null || this.event.extendedProps.stepUserIds === undefined) {
        return null;
      }
      return this.steps[0]?.users?.filter(x => this.event.extendedProps.stepUserIds.includes(x.id));
    },
    first_users_abr() {
      if(this.users === null) {
        return null;
      }
      return this.users != null && this.users.length === 1 ? this.users[0].displayName.charAt(0) : "+";
    },
    steps() {
      if(this.event.extendedProps.stepIds === null || this.event.extendedProps.stepIds === undefined) {
        return null;
      }
      return this.$store.state.calendar.progress.filter(x => this.event.extendedProps.stepIds.includes(x.stepIdPublic))
    }
  },
};
</script>
<template>
  <div>
    <div v-if="event.display == 'background'" style="color: white"
      class="d-flex blocker-title justify-content-center align-items-center position-absolute">
      <div style="font-size: 0.8125rem;">{{ event.title }}</div>
    </div>
    <div id="hasmenu" style="border-radius: 5px; padding: 2px; position: relative; width: 100%" :style="{
      border: event.extendedProps.selected
        ? '2px dashed #8fbce6'
        : '2px dashed transparent',
      'background-color': event.extendedProps.eventType != 'BlockerEvent' ? 'white' : info.backgroundColor,
    }" v-if="hasExtendedProps && event.display != 'background'">
      <div v-if="displayOverlay" class="eventOverlay" style="
          z-index: 9999;
          border-radius: 5px;
          position: absolute;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          background: #f4f3f48a;
        "></div>
      <div style="
          z-index: 9998;
          border-radius: 3px;
          position: absolute;
          top: 2px;
          bottom: 2px;
          width: 7px;
          margin-left: auto;
        " :style="{ 'background-color': pillColor }" v-if="event.extendedProps.eventType != 'BlockerEvent'"></div>
      <div style="
          height: 15.5px;
          font-weight: 300;
          font-size: 0.9em;
          margin-left: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        " :style="{ 'color': event.extendedProps.eventType == 'BlockerEvent' ? info.fontColor : '#7b797d' }">
        <div style="
            overflow: hidden;
            padding-right: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
            display: inline-block;
            flex-grow: 1;
            flex-basis: 0;
            text-align: start;
          ">
          {{ event.title }}
        </div>
        <div 
          v-if="
            event.extendedProps.eventType === 'StepEvent' && 
            view.type === 'listWeek' &&
            !mobileScreen
          " 
          class="pr-1 pl-1 rounded-pill" 
          style="font-size: small; color: #fff; font-weight: 600;" 
          :style="{ 'background-color': steps[0].colorHexa }"
        > 
          {{ steps[0].name }}
        </div>
        <div v-if="fields.find(x => x.name == 'user' && x.checked == true) && first_users_abr != null" class="rounded-circle header-profile-user" style="
                      height: 17px;
                      width: 17px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: #f3a821;
                    "
            v-b-tooltip
            :title="users.map(x => x.displayName).join(', ')">
          <span style="
                        font-weight: 500;
                        color: white;
                      ">{{ first_users_abr }}</span>
        </div>
        <div 
          style="
            display: flex; 
            align-items: center; 
            flex-basis: 0;
            justify-content: end;
          "
        >
          <!-- need to figure out address format -->
          <i
            v-if="
              fields.find(x => x.name == 'address' && x.checked == true) && 
              event.extendedProps.workOrderClientAddress &&
              event._context.viewApi.type != 'listWeek'
            " 
            v-b-tooltip
            :title="event.extendedProps.workOrderClientAddress"
            style="font-size: 1.2em" 
            class='mdi mdi-map-marker mr-1 ml-1'
          />
          <i
            v-if="fields.find(x => x.name == 'comment' && x.checked == true) && event.extendedProps.comment
            && event._context.viewApi.type !== 'listWeek'" 
            v-b-tooltip
            :title="event.extendedProps.comment"
            style="font-size: 1.2em"
            class='bx bxs-comment-dots mr-1'
          />
          <i
            v-if="fields.find(x => x.name == 'workOrderComment' && x.checked == true) && event.extendedProps.workOrderComment
            && event._context.viewApi.type !== 'listWeek'" 
            v-b-tooltip
            :title="event.extendedProps.workOrderComment"
            style="font-size: 1.2em" 
            class='bx bxs-comment-dots mr-1'
          />
          <i
            v-if="fields.find(x => x.name == 'projectManager' && x.checked == true) && event.extendedProps.workOrderProjectManager" 
            v-b-tooltip
            :title="event.extendedProps.workOrderProjectManager"
            style="font-size: 1.5em" 
            class='bx bxs-user-pin mr-1'
          />
          <i
            v-if="fields.find(x => x.name == 'contractor' && x.checked == true) && event.extendedProps.workOrderContractor" 
            v-b-tooltip
            :title="event.extendedProps.workOrderContractor"
            style="font-size: 1.5em" 
            class='bx bxs-business mr-1'
          />
          <i
            v-if="fields.find(x => x.name == 'clientName2' && x.checked == true) && event.extendedProps.workOrderClientName" 
            v-b-tooltip
            :title="event.extendedProps.workOrderClientName"
            style="font-size: 1.5em" 
            class='bx bxs-user-detail mr-1'
          />
          <i
            v-if="fields.find(x => x.name == 'group' && x.checked == true) && event.extendedProps.workOrderGroupName" 
            v-b-tooltip
            :title="event.extendedProps.workOrderGroupName"
            style="font-size: 1.3em" 
            class='mdi mdi-select-group mr-1'
          />
          <i
            v-if="fields.find(x => x.name == 'projectType' && x.checked == true) && event.extendedProps.workOrderProjectType" 
            v-b-tooltip
            :title="event.extendedProps.workOrderProjectType"
            style="font-size: 1.2em" 
            class='bx bxs-briefcase mr-1'
          />
          <i v-if="fields.find(x => x.name == 'showCadCAm' && x.checked == true) &&
            event.extendedProps.workOrderIsCadCamMerged == true
            " class="mdi mdi-database-check mr-1" style="font-size: 1.2em; color: #34c38f">
          </i>
          <span 
            v-if="
              event.extendedProps.eventType == 'InstallationEvent' && 
              event.extendedProps.userCreated == false &&
              event.extendedProps.isMainEvent &&
              event._context.viewApi.type == 'dayGridMonth' &&
              fields.find(x => x.name == 'installer' && x.checked == true)
              "
            style="font-weight: 500;"
            class="pr-1"
          >
            <span v-if="installers.length == 1">
              {{ installers[0].code }}
            </span>
            <span v-else-if="installers.length > 1" class="iconClass">
              <i class='bx bxs-user'></i>
              <span class="badge badge-secondary">{{ installers.length }}</span>
            </span>
          </span>
          <span 
            v-if="
              event.extendedProps.eventType == 'InstallationEvent' && 
              event.extendedProps.userCreated == false && 
              !event.extendedProps.isMainEvent &&
              event._context.viewApi.type == 'dayGridMonth' &&
              fields.find(x => x.name == 'installer' && x.checked == true)
            "
            style="font-weight: 500;"
            class="pr-2"
          >
            <span v-if="installers.length == 1">
              {{ installers[0].code }}
            </span>
            <span v-else-if="installers.length > 1" class="iconClass">
              <i class='bx bxs-user'></i>
              <span class="badge badge-secondary">{{ installers.length }}</span>
            </span>
          </span>
          <i v-if="
            event.extendedProps.eventType == 'InstallationEvent' &&
            event.extendedProps.userCreated == false &&
            event.extendedProps.isMainEvent == null ||
            event.extendedProps.isMainEvent == true
          " style="font-size: 1.2em" class='mdi mdi-hammer-screwdriver mr-1 text-warning'></i>
          <i v-if="
            event.extendedProps.eventType == 'InstallationEvent' &&
            event.extendedProps.userCreated == false &&
            event.extendedProps.isMainEvent == false
          " style="font-size: 1.2em" class='mdi mdi-hammer-screwdriver mr-1'></i>
          <i v-if="
            event.extendedProps.eventType == 'DeliveryEvent' &&
            event.extendedProps.userCreated == false
          " style="font-size: 1.2em" class="mdi mdi-truck mr-1"></i>
          <i v-if="
            event.extendedProps.eventType == 'ProductionEvent' &&
            event.extendedProps.userCreated == false" 
            class="fas fa-cogs mr-1"></i>
          <i v-if="
            event.extendedProps.eventType == 'StepEvent' &&
            event.extendedProps.userCreated == false &&
            userCanSeeDamaged(steps[0]) &&
            event.extendedProps.workOrderHasDamaged
          " style="font-size: 1.2em" class="mdi mdi-alert-outline text-danger mr-1"></i>
          <i v-if="
            event.extendedProps.eventType == 'StepEvent' &&
            event.extendedProps.userCreated == false &&
            userCanSeeBackOrder(steps[0]) &&
            event.extendedProps.workOrderHasBackOrder
          " style="font-size: 1.2em" class="mdi mdi-alert-outline text-danger mr-1"></i>
          <i v-if="
            event.extendedProps.eventType == 'StepEvent' &&
            event.extendedProps.userCreated == false &&
            !event.extendedProps.breakLinkToReferenceDate &&
            fields.find(x => x.name == 'linkToReferenceDate' && x.checked == true)
          " style="font-size: 1.2em" class="mdi mdi-link-variant mr-1"></i>
          <i v-if="
            event.extendedProps.eventType == 'StepEvent' &&
            event.extendedProps.userCreated == false &&
            event.extendedProps.breakLinkToReferenceDate &&
            fields.find(x => x.name == 'link' && x.checked == true)
          " style="font-size: 1.2em" class="mdi mdi-link-variant-off mr-1"></i>
          <div 
            style="
              width: 30px;
              height: 15.5px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              padding: 2px;
              font-size: 0.8em;
              font-weight: bold;
            " 
            :style="{
              border: info.border,
              color: daysBeforeReferenceDate < 0 ? 'white' : info.fontColor,
              'background-color': daysBeforeReferenceDate < 0 && event.extendedProps.eventType == 'StepEvent' && !completed && !started ? '#ff5252' : info.backgroundColor,
            }" 
            v-if="event.extendedProps.eventType != 'BlockerEvent'"
          >
            <i v-if="event.extendedProps.eventType == 'StepEvent' && completed" style="color: #34c38f" class="fas fa-check"/>
            <i v-if="event.extendedProps.eventType == 'StepEvent' && started" style="color: #556ee6" class="mdi mdi-clock-start"/>
            <i
              v-if="event.extendedProps.userCreated" 
              style="
                position: absolute;
                top: 3px;
                right: 6px;
                font-size: 1.5em;
                color: #556ee6;
              " class="far fa-user-circle"
            />
            <template v-if="event.extendedProps.eventType == 'StepEvent' && !completed && !started">
              {{ daysBeforeReferenceDate }}
            </template>
            <template 
              v-if="
                event.extendedProps.userCreated == false &&
                (event.extendedProps.eventType == 'InstallationEvent' ||
                  event.extendedProps.eventType == 'DeliveryEvent' ||
                  event.extendedProps.eventType == 'ProductionEvent')
              "
            >
              {{
                Math.floor(
                  event.extendedProps.workOrderPercentCompleted * 100
                ) + "%"
              }}
            </template>
          </div>
        </div>
      </div>
      <div v-if="shipperCodes != null && event._context.viewApi.type != 'dayGridMonth'" style="
          color: #7b797d;
          height: 15.5px;
          font-weight: 300;
          font-size: 0.9em;
          margin-left: 10px;
          text-align: left;
        ">
        <div v-if="shipperCodes.length >= 1">
          <div v-for="(shipperCode, index) of shipperCodes" :key="index">
            {{ shipperCode }}
          </div>
        </div>
      </div>
      <div 
        v-if="
          event.extendedProps.eventType == 'InstallationEvent' && 
          event._context.viewApi.type != 'dayGridMonth' &&
          fields.find(x => x.name == 'installer' && x.checked == true)
        " 
        style="
          color: #7b797d;
          height: auto;
          font-weight: 300;
          font-size: 0.9em;
          margin-left: 10px;
          text-align: left;
        "
      >
        <div v-if="event.extendedProps.isMainEvent && installers.length >= 1">
          <div v-for="(installer, index) of installers" :key="index">
            {{ installer.code }}
          </div>
        </div>
        <div v-else-if="!event.extendedProps.isMainEvent && installers.length >= 1">
          <div v-for="(installer, index) of installers" :key="index">
            {{ installer.code }}
          </div>
        </div>
      </div>
      <div 
        v-if="
          event._context.viewApi.type == 'listWeek' &&
          event.extendedProps.workOrderClientAddress &&
          fields.find(x => x.name == 'address' && x.checked == true)
        " 
        style="
          color: #7b797d;
          font-weight: 300;
          font-size: 0.9em;
          margin-left: 10px;
          text-align: left;
        "
        class="text-wrap"
      >
        {{ $t("general.clientAddress") +": "+ event.extendedProps.workOrderClientAddress }}
      </div>
      <div 
        v-if="
          event._context.viewApi.type == 'listWeek' &&
          event.extendedProps.comment &&
          fields.find(x => x.name == 'comment' && x.checked == true)
        " 
        style="
          color: #7b797d;
          font-weight: 300;
          font-size: 0.9em;
          margin-left: 10px;
          text-align: left;
        "
        class="text-wrap"
      >
        {{ $t("general.comment") +": "+ event.extendedProps.comment }}
      </div>
      <div 
        v-if="
          event._context.viewApi.type == 'listWeek' &&
          event.extendedProps.workOrderComment &&
          fields.find(x => x.name == 'workOrderComment' && x.checked == true)
        " 
        style="
          color: #7b797d;
          font-weight: 300;
          font-size: 0.9em;
          margin-left: 10px;
          text-align: left;
        "
        class="text-wrap"
      >
        {{ $t("general.workOrderComment") +": "+ event.extendedProps.workOrderComment }}
      </div>
    </div>
  </div>
</template>

<style scoped>

.badge-wrapper {
  position: relative;
}

.badge {
  position: absolute;
  top: -5px;
  right: -10px;
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: rgb(123, 121, 125);
  font-weight: 800;
  font-family: sans-serif;
  font-size: 0.5rem;
}
.iconClass{
  position: relative;
}
.iconClass span{
  position: absolute;
  bottom: 10px;
  left:  10px;
  display: block;
}
</style>