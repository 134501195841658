<script>
// eslint-disable-next-line no-unused-vars
import CalendarApiService from "@/services/calendarService";

export default {
  components: {
  },
  props: ['capacities', 'isLoading'],
  data(){
    return {
      capacityContainerOpened: true,
    }
  },
  computed: {

  },
  watch :{

  },  
  methods:{
    toggleCapacityContainer(){
      this.capacityContainerOpened = !this.capacityContainerOpened;
    },
  }
};
</script>
<template>
  <div v-if="capacities.length > 0" id="installersCapacityContainer" class="card mb-3 infoContainer">  
    <b-button class="mr-2 infoContainer" @click="toggleCapacityContainer()" style="border:none; background:white; display:flex; width:100%; justify-content: space-between; align-items:center;">{{$t('general.installersCapacities')}} <i v-bind:class="{'fas fa-angle-down': !capacityContainerOpened,  'fas fa-angle-up': capacityContainerOpened}"></i></b-button>
    <b-collapse v-model="capacityContainerOpened" class="">
      <b-card style="margin-top:-6px; margin-bottom:0;">
        <loading :active.sync="isLoading" 
          :can-cancel="false"
          color='#f1b44c'
          :width=70
          :height=70
          loader='dots'
          :is-full-page="false"></loading>          
        <div v-for="(capacity, index) in capacities" :key="index" class="mb-3">
          <b-progress class="mb-1" height="20px" :class="{'underCapacity' : capacity.totalValue <= capacity.min, 'overCapacity' : capacity.totalValue >= capacity.max, 'inRangeCapacity' : (capacity.totalValue >= capacity.min && capacity.totalValue <= capacity.max)}" :value="capacity.totalValue" :max="capacity.max" show-value></b-progress>
          <div style="display:flex; justify-content:space-between;">
            <small style="font-weight:500;">{{$t('general.' + capacity.measureType)}}</small>
            <small>{{capacity.min}} | {{capacity.max}}</small>
          </div> 
        </div>
      </b-card>
    </b-collapse>           
  </div>
</template>