
<script>
// eslint-disable-next-line no-unused-vars
import DatePicker from "vue2-datepicker";
// eslint-disable-next-line no-unused-vars
import CalendarApiService from "@/services/calendarService";
// eslint-disable-next-line no-unused-vars
import { required, email } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import { Sketch } from "vue-color";

export default {
  components: {
    DatePicker,
    FormWizard,
    TabContent,
    Sketch,
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      oldStartDate: "",
      colors: { r: 255, g: 0, b: 0 },
      separateEvents: false,
      eventForm: {
        title: null,
        allDay: true,
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        comment: null,
        customHexColor: "#FFC25D",
      },
      showColorPicker: false,
      confirm: null,
      close: null,
      selectedProgress: [],
      selectedInstallers: [],
      selectedShippers: [],
      resourceSelected: false,
      eventFormSubmit: false,
      eventType: "user",
    };
  },
  created() { },
  beforeDestroy() { },
  computed: {
    progress() {
      return this.$store.state.calendar.progress;
    },
    installers() {
      return this.$store.state.calendar.installers;
    },
    shippers() {
      return this.$store.state.calendar.shippers;
    },
  },
  methods: {
    changeAllDay() {
      if (this.eventForm.allDay) {
        //update end time
        let newEndDate = this.$dayjs(this.eventForm.startDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
        this.eventForm.endDate = newEndDate;
        this.eventForm.endTime = null;
      }
    },
    changeStartDate(newStartDate) {
      // move end date
      let diff = this.$dayjs(newStartDate).diff(this.oldStartDate);
      this.eventForm.endDate = this.$dayjs(this.eventForm.endDate)
        .add(diff, "millisecond")
        .format("YYYY-MM-DD");
    },
    openBlockerEventDialog: function (data) {
      this.showColorPicker = false;
      if (data.selectedProgress != [] || data.selectedInstallers != [] || data.selectedShippers != []) {
        this.resourceSelected = true;
      }
      return new Promise((resolve, reject) => {
        this.eventType = "blocker";
        this.eventForm.customHexColor = data.backgroundColor ?? "#FFC25D";
        this.selectedProgress =
          data.selectedProgress ??
          this.progress.filter(function (p) {
            return data.extendedProps.selectedProgress.includes(p.id);
          });
        this.selectedShippers =
          data.selectedShippers ??
          this.shippers.filter(function (p) {
            return data.extendedProps.selectedShippers.includes(p.idPublic);
          });
        this.selectedInstallers =
          data.selectedInstallers ??
          this.installers.filter(function (p) {
            return data.extendedProps.selectedInstallers.includes(p.idPublic);
          });

        this.eventForm.allDay = true;
        this.eventForm.startDate = this.$dayjs(data.start).format("YYYY-MM-DD");
        this.eventForm.endDate = data.end
          ? this.$dayjs(data.end).format("YYYY-MM-DD")
          : this.$dayjs(this.eventForm.startDate)
            .add(1, "day")
            .format("YYYY-MM-DD");
        this.eventForm.id = data.id;
        this.eventForm.title = data.title ?? "";
        this.showModal = true;
        this.confirm = () => {
          let model = {
            customHexColor: this.eventForm.customHexColor,
            startDate: this.$dayjs(this.eventForm.startDate).format(
              "YYYY-MM-DD"
            ),
            endDate: this.$dayjs(this.eventForm.endDate).format("YYYY-MM-DD"),
            title: this.eventForm.title,
            id: data.id,
            selectedProgress: this.selectedProgress.map((p) => {
              return p.id;
            }),
            selectedShippers: this.selectedShippers.map((s) => {
              return s.idPublic;
            }),
            selectedInstallers: this.selectedInstallers.map((i) => {
              return i.idPublic;
            }),
          };
          if (data.id == null) {
            CalendarApiService.createBlockerEvent(model).then(
              () => {
                this.trackEvent("CreateBlockerEvent");
                resolve();
              },
              (result) => reject(result)
            );
          } else {
            CalendarApiService.updateBlockerEvent(model).then(
              () => {
                this.trackEvent("UpdateBlockerEvent");
                resolve();
              },
              (result) => reject(result)
            );
          }
          return true;
        };
        this.close = () => {
          reject();
          this.resetFields();
        };
      }).finally(() => {
        this.showModal = false;
      });
    },
    openCreateUserEventDialog: function (data) {
      this.eventType = "user";
      this.eventForm.title = "";
      if (data.selectedProgress != [] || data.selectedInstallers != [] || data.selectedShippers != []) {
        this.resourceSelected = true;
      }
      return new Promise((resolve, reject) => {
        this.selectedProgress = data.selectedProgress;
        this.selectedShippers = data.selectedShippers;
        this.selectedInstallers = data.selectedInstallers;

        this.eventForm.allDay = true;
        this.eventForm.startDate = this.$dayjs(data.startDateTime).format(
          "YYYY-MM-DD"
        );
        this.eventForm.startTime = this.$dayjs(data.startDateTime).format(
          "HH:mm"
        );
        this.eventForm.endDate = this.$dayjs(this.eventForm.startDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
        this.showModal = true;
        this.confirm = () => {
          let model = {
            title: this.eventForm.title,
            allDay: this.eventForm.allDay,
            startDate: this.eventForm.startDate,
            startTime: this.eventForm.startTime,
            endDate: this.eventForm.endDate,
            endTime: this.eventForm.endTime,
            customHexColor: this.eventForm.customHexColor,
            comment: this.eventForm.comment,
            separateAppointments: this.separateEvents,
            selectedProgress: this.selectedProgress.map((p) => {
              return p.id;
            }),
            selectedShippers: this.selectedShippers.map((s) => {
              return s.idPublic;
            }),
            selectedInstallers: this.selectedInstallers.map((i) => {
              return i.idPublic;
            }),
          };
          CalendarApiService.CreateUserAppointment(model).then(() => {
            this.trackEvent("CreateUserEvent");
            if (this.separateEvents == true) {
              var msg = this.$t("general.eventsCreationSuccessMsg");
              msg = msg.replace("{title}", this.eventForm.title);
              this.makeToast("Success", msg, "success");
            } else {
              var msg2 = this.$t("general.eventCreationSuccessMsg");
              msg2 = msg2.replace("{title}", this.eventForm.title);
              this.makeToast("Success", msg2, "success");
            }
            resolve();
          },
            (result) => reject(result)
          );
          return true;
        };
        this.close = () => {
          reject();
          this.resetFields();
        };
      }).finally(() => {
        this.showModal = false;
        this.resetFields();
      });
    },
    resetFields() {
      this.eventForm = {
        title: null,
        allDay: true,
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        comment: null,
        customHexColor: "#FFC25D",
      };
      this.eventType = "user";
      this.eventFormSubmit = false;
      this.resourceSelected = false;
    },
    getStartDateTime() {
      let startDate = "";

      if (this.eventForm.allDay == true) {
        startDate = this.eventForm.startDate;
      } else {
        startDate = this.$dayjs(
          this.eventForm.startDate + "T" + this.eventForm.startTime
        ).format("YYYY-MM-DDTHH:mm");
      }

      return startDate;
    },
    getEndDateTime() {
      let endDate = "";

      if (this.eventForm.allDay == true) {
        endDate = this.eventForm.endDate;
      } else {
        endDate = this.$dayjs(
          this.eventForm.endDate + "T" + this.eventForm.endTime
        ).format("YYYY-MM-DDTHH:mm");
      }

      return endDate;
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },
    updateColorProgress(color) {
      this.eventForm.customHexColor = color.hex;
    },
    validateEvent() {
      this.eventFormSubmit = true;
      this.$v.eventForm.$touch();

      if (!this.$v.eventForm.$invalid) {
        return true;
      } else {
        return false;
      }
    },
    validateResource() {
      this.eventFormSubmit = true;
      this.$v.selectedProgress.$touch();
      this.$v.selectedInstallers.$touch();
      this.$v.selectedShippers.$touch();

      if (!this.$v.selectedProgress.$invalid || !this.$v.selectedInstallers.$invalid || !this.$v.selectedShippers.$invalid) {
        this.resourceSelected = true;
        this.confirm();
        return true;
      } else {
        this.resourceSelected = false;
        return false;
      }
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 5000,
        appendToast: false,
        variant: variant,
        solid: true,
      });
    },
    selectAllProgress() {
      this.selectedProgress = this.progress;
    },
    unselectAllProgress() {
      this.selectedProgress = [];
    },
    selectAllShippers() {
      this.selectedShippers = this.shippers;
    },
    unselectAllShippers() {
      this.selectedShippers = [];
    },
    selectAllInstallers() {
      this.selectedInstallers = this.installers;
    },
    unselectAllInstallers() {
      this.selectedInstallers = [];
    },
  },
  validations: {
    eventForm: {
      title: { required },
    },
    selectedProgress: {required},
    selectedInstallers: {required},
    selectedShippers: {required},
  },
};
</script>

<template>
  <b-modal v-model="showModal" :title="
    eventType == 'user'
      ? $t('general.eventCreationWizard')
      : eventForm.id == null
        ? $t('general.createBlockerEvent')
        : $t('general.editBlockerEvent')
  " title-class="text-black font-18" hide-footer body-class="p-3" size="md" no-close-on-backdrop>
    <loading :active.sync="isLoading" :can-cancel="false" color="#f1b44c" :width="70" :height="70" loader="dots"
      :is-full-page="false">
    </loading>
    <form id="userEventCreationForm" @submit.prevent="">
      <div class="row">
        <div class="col-12">
          <form-wizard color="#556ee6" :nextButtonText="$t('general.next')" :backButtonText="$t('general.back')"
            :finishButtonText="$t('general.finish')">
            <tab-content icon="fas fa-info" :before-change="validateEvent">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <b-form-group class="required" style="position: relative">
                        <label>{{ $t("general.title") }}</label>
                        <b-form-input :class="{
                          'is-invalid':
                            eventFormSubmit && $v.eventForm.title.$error,
                        }" v-model="eventForm.title" :placeholder="$t('general.title')">
                        </b-form-input>
                        <div v-if="eventFormSubmit && $v.eventForm.title.$error" class="invalid-feedback">
                          <span v-if="!$v.eventForm.title.required">This value is required.</span>
                        </div>
                      </b-form-group>
                      <b-form-group style="position: relative">
                        <label>{{ $t("general.color") }}</label>
                        <div class="mb-2" style="display: flex; align-items: center">
                          <div class="mr-2" :style="{ background: eventForm.customHexColor }" style="
                                width: 27px;
                                height: 27px;
                                border-radius: 5px;
                              "></div>
                          <b-button @click="toggleColorPicker()" v-show="!showColorPicker" size="sm" variant="primary">{{
                            $t("general.selectColor") }}</b-button>
                          <b-button @click="toggleColorPicker()" v-show="showColorPicker" size="sm" variant="primary">{{
                            $t("general.close") }}</b-button>
                        </div>
                        <Sketch v-show="showColorPicker" :value="eventForm.customHexColor" @input="updateColorProgress" />
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <b-form-checkbox v-model="eventForm.allDay" v-if="eventType == 'user'" @change="changeAllDay()"
                        class="custom-checkbox mb-3 mt-2">{{ $t("general.allDayEvent") }}</b-form-checkbox>
                    </div>
                    <div class="col-6">
                      <b-form-group class="font-weight-medium">
                        <label>{{ $t("general.startDate") }}</label>
                        <date-picker v-model="eventForm.startDate" @focus="oldStartDate = eventForm.startDate"
                          @change="changeStartDate" :first-day-of-week="1"
                          :format="this.$dayjs.localeData().longDateFormat('L')" value-type="YYYY-MM-DD" lang="en">
                        </date-picker>
                      </b-form-group>
                      <b-form-group v-if="!eventForm.allDay" :label="$t('general.startTime')" class="font-weight-medium">
                        <date-picker v-model="eventForm.startTime" format="HH:mm" value-type="format" type="time"
                          placeholder="HH:mm"></date-picker>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group :label="$t('general.endDate')" class="font-weight-medium">
                        <date-picker v-model="eventForm.endDate" :first-day-of-week="1"
                          :format="this.$dayjs.localeData().longDateFormat('L')" value-type="YYYY-MM-DD"
                          lang="en"></date-picker>
                      </b-form-group>
                      <b-form-group v-if="!eventForm.allDay" :label="$t('general.endTime')" class="font-weight-medium">
                        <date-picker v-model="eventForm.endTime" format="HH:mm" value-type="format" type="time"
                          placeholder="HH:mm"></date-picker>
                      </b-form-group>
                    </div>
                    <div class="col-12" v-if="eventType == 'user'">
                      <b-form-group class="font-weight-medium" :label="$t('general.comment')">
                        <textarea v-model="eventForm.comment" class="form-control" :maxlength="225" rows="3"
                          :placeholder="$t('general.comment')"></textarea>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content icon="mdi mdi-format-list-checkbox" :before-change="validateResource">
              <h5 class="mb-4">{{ $t("general.selectInsertEvent") }}</h5>
              <b-form-checkbox v-if="eventType == 'user'" v-model="separateEvents" class="custom-checkbox mb-3">{{
                $t("general.separateEventsOption") }}</b-form-checkbox>
              <div v-if="eventFormSubmit && !this.resourceSelected">
                <span class="text-danger">This value is required.</span>
              </div>
              <b-form-group>
                <div style="display: flex">
                  <h6>{{ $t("general.progress") }}</h6>
                  <div style="margin-left: auto">
                    <a @click="selectAllProgress" style="line-height: 1.2; margin-left: 10px" href="#">{{
                      $t("general.selectAll") }}</a>
                    <a @click="unselectAllProgress" style="line-height: 1.2; margin-left: 10px" href="#">{{
                      $t("general.unselectAll") }}</a>
                  </div>
                </div>
                <multiselect v-model="selectedProgress" :placeholder="$t('general.selectProgress')" label="name" track-by="id"
                  :options="progress" :multiple="true"></multiselect>
              </b-form-group>

              <b-form-group>
                <div style="display: flex">
                  <h6>{{ $t("general.shippers") }}</h6>
                  <div style="margin-left: auto">
                    <a @click="selectAllShippers" style="line-height: 1.2; margin-left: 10px" href="#">{{
                      $t("general.selectAll") }}</a>
                    <a @click="unselectAllShippers" style="line-height: 1.2; margin-left: 10px" href="#">{{
                      $t("general.unselectAll") }}</a>
                  </div>
                </div>
                <multiselect v-model="selectedShippers" :placeholder="$t('general.selectShippers')" label="name" track-by="id"
                  :options="shippers" :multiple="true"></multiselect>
              </b-form-group>

              <b-form-group>
                <div style="display: flex">
                  <h6>{{ $t("general.installers") }}</h6>
                  <div style="margin-left: auto">
                    <a @click="selectAllInstallers" style="line-height: 1.2; margin-left: 10px" href="#">{{
                      $t("general.selectAll") }}</a>
                    <a @click="unselectAllInstallers" style="line-height: 1.2; margin-left: 10px" href="#">{{
                      $t("general.unselectAll") }}</a>
                  </div>
                </div>
                <multiselect v-model="selectedInstallers" :placeholder="$t('general.selectInstallers')" label="name" track-by="id"
                  :options="installers" :multiple="true"></multiselect>
              </b-form-group>
            </tab-content>
            <template slot="custom-buttons-right">
              <b-button @click="close" class="ml-1">{{
                $t("general.cancel")
              }}</b-button>
            </template>
          </form-wizard>
        </div>
      </div>
    </form>
  </b-modal>
</template>