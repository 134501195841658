
<script>



export default {
  components: {

  },
  data() {
    return {
      showModal: false,
      callBack: null
    };
  },
  created(){
    this.$bus.$on('open-confirm-move-broken-link-dialog', this.openConfirmMoveBrokenLinkDialog)
  },
  beforeDestroy() {
    this.$bus.$off('open-confirm-move-broken-link-dialog', this.openConfirmMoveBrokenLinkDialog)
  },   
  computed: {

  },
  methods: {
    openConfirmMoveBrokenLinkDialog: function(data){
      this.callBack = data.callBack;
      this.showModal = true;
    },
    closeModal(userChoice){
      this.showModal = false;
      this.callBack(userChoice);
    }
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.moveDetachedEvents')"
    title-class="text-black font-18"
    scrollable
    body-class="p-3"
    id="confirmMoveBrokenLinkModal"
    no-close-on-backdrop
  >
    <div class="row">
      <div class="col-12">
        <p>{{$t('general.someProgressHaveBeenMoved')}}</p> 
        <p>{{$t('general.wouldYouLikeToFollowReference')}}</p>
      </div>
    </div>
    <template #modal-footer class="pt-3">
      <b-button @click="closeModal(null)" class="ml-1">{{$t('general.cancel')}}</b-button>
      <div>
        <b-button @click="closeModal(false)" class="ml-1">{{$t('general.no')}}</b-button>
        <b-button @click="closeModal(true)" class="ml-1" variant="primary">{{$t('general.yes')}}</b-button>
      </div>
    </template>
  </b-modal>
</template>