<script>
import UserConfigMixin from "@/mixins/userConfigMixin";

export default {
  mixins: [UserConfigMixin],
  props: ["fields"],
  data() {
    return {};
  },
  created() {
    for (let field of this.fields) {
      field.checked = !localStorage.getItem(field.name) 
      ? false 
      : localStorage.getItem(field.name) == "true" ?? true;
    }
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
  },
  computed: {
    fieldsComputed() {
      return this.fields.filter(x => x.authorisation !== false);
    }
  },
};
</script>

<template>
  <b-dropdown
    id="cogDropdown"
    right
    variant="primary"
    :text="$t('general.columns')"
  >
    <template #button-content>
      <i style="font-size: 1.6em" class="bx bx-cog"></i>
    </template>
    <b-dropdown-form v-for="(field, index) in fieldsComputed" :key="index">
      <b-form-checkbox
        @change="$emit('change')"
        class="mr-3"
        size="md"
        switch
        v-model="field.checked"
      >
        <span style="white-space: nowrap">
          <i v-if="field.icon" style="font-size: 1.2em" :class="field.icon + ' mr-1'"/>
          {{ $t(`general.${field.name}`) }}
        </span>
      </b-form-checkbox>
    </b-dropdown-form>
  </b-dropdown>
</template>
