<script lang="jsx">
import FullCalendar from "@fullcalendar/vue";
import EventTemplate from "./event-template";
import { formatDate, formatRange } from "@fullcalendar/core";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import CalendarApiService from "@/services/calendarService";
import EventService from "@/services/event-service";
export default {
  model: {
    prop: "state",
  },
  props: ["options", "state"],
  components: {
    FullCalendar,
    EventTemplate
  },
  data() {
    return {
      isLoading: false,
      eventSrcLoading: [],
      internalCalendarOptions: {
        eventSources: [
          { id: 'StepEvent', events: this.getProgressEventSrcCallBack() },
          { id: 'DeliveryEvent', events: this.getDeliveryEventSrcCallBack() },
          { id: 'InstallationEvent', events: this.getInstallationEventSrcCallBack() },
          { id: 'ProductionEvent', events: this.getProductionEventSrcCallBack() },
          { id: 'UserEvent', events: this.getUserEventSrcCallBack() },
          { id: 'BlockerEvent', events: this.getBlockerEventSrcCallBack() }
        ],
        locales: allLocales,
        customButtons: {
          calendarButton: {
            text: "Calendar",
            click: function () {},
          },
          quickViewButton: {
            text: "Quick view",
            click: function () {},
          },
          toolBarButton: {
            text: "+ Tools",
            click: () => {
              this.openToolbar();
            },
          },
        },
        schedulerLicenseKey: "0426684214-fcs-1702331659",
        locale: this.$i18n.localeComplete,
        timeZone: "local",
        eventDisplay: "auto",
        themeSystem: "bootstrap",
        editable: true,
        droppable: true,
        weekends: true,
        selectable: true,
        selectMirror: false,
        height: "auto",
        fixedWeekCount: false,
        showNonCurrentDates: true,
        nextDayThreshold: "00:00:00",
        headerToolbar: false,
        eventOrder: "completed,start,title",
        views: {
          workWeek: {
            type: "timeGridWeek",
            hiddenDays: [0, 6],
          },
          dayGrid: {
            // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
            dayMaxEventRows: true,
          },
          timeGrid: {
            dayMaxEventRows: 12,
            // options apply to timeGridWeek and timeGridDay views
          },
          week: {
            // options apply to dayGridWeek and timeGridWeek views
          },
          day: {
            // options apply to dayGridDay and timeGridDay views
            dayHeaderFormat: { weekday: "long", month: "long", day: "numeric" },
          },
        quickView: {
            type: "resourceTimeline",
            slotDuration: "24:00",
            eventMinWidth: 130,
            resourceOrder: "resourceTypeOrder, order",
            slotLabelFormat: [
                { weekday: "short", day: "numeric", month: "numeric" },
            ],
            slotLabelClassNames: (arg) => {
                let date = this.$dayjs(new Date()).format("YYYY-MM-DD");
                let slotDate = this.$dayjs(arg.date).format("YYYY-MM-DD");
                if (slotDate == date) {
                return "current-day";
              }
            },
            duration: { days: 5 },
            resourceAreaWidth: "20%",
            dateIncrement: { days: 1 },
          },
        },
        resourceAreaColumns: [
          {
            headerContent: "",
          },
        ],
        windowResize: () => {
          this.$refs.fullCalendar
            .getApi()
            .setOption("height", this.$el.clientHeight);
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
          resourceTimelinePlugin,
        ]
      }
    };
  },
  methods: {
    next() {
      this.state.setSrc = false;
      this.$refs.fullCalendar.getApi().next();
      this.state.updateDisplayMonth++;
    },
    today() {
      this.state.setSrc = false;
      this.$refs.fullCalendar.getApi().today();
      this.state.updateDisplayMonth++;
    },
    previous() {
      this.state.setSrc = false;
      this.$refs.fullCalendar.getApi().prev();
      this.state.updateDisplayMonth++;
    },
    previousDay() {
      this.state.setSrc = false;

      this.$refs.fullCalendar.getApi().incrementDate({
        days: -1
      });
      this.state.updateDisplayMonth++;
    },
    previousWeek() {
      this.state.setSrc = false;
      this.$refs.fullCalendar.getApi().incrementDate({
        weeks: -1
      })
      this.state.updateDisplayMonth++;
    },
    nextWeek() {
      this.state.setSrc = false;
      this.$refs.fullCalendar.getApi().incrementDate({
        weeks: 1
      })
      this.state.updateDisplayMonth++;
    },
    goToDate(date) {
      this.state.setSrc = false;

      this.$refs.fullCalendar.getApi().gotoDate(date);
      this.$refs.fullCalendar.getApi().select(date);
      this.state.updateDisplayMonth++;
    },
    setCalendarView(value) {
      this.$store.commit("calendar/setCalendarView", value);
      this.state.view = value;
      this.$refs.fullCalendar.getApi().changeView(value);
      this.state.updateDisplayMonth++;
    },
    refetchEvents() {
        this.$refs.fullCalendar.getApi().refetchEvents();
    },
    getProgressEventSrcCallBack() {
      let callback = (info, successCallback) => {
        if(this.state.sources.steps.length === 0) {
          this.setResponseEventSrc([], 'StepEvent', successCallback, this.state.sources.steps);
        } else {
          let selectedSteps = this.state.sources.steps;
          let start = this.$dayjs(info.start).format("YYYY-MM-DD");
          let end = this.$dayjs(info.end).format("YYYY-MM-DD");
          let filterModel = this.getWorkOrderFilterModel();

          //Debounce pour ne pas pouvoir spammer le bouton next/ previous
          var timeout = this.state.setSrc == true ? 0 : 500;
          if (typeof window.progressEventTimer == "undefined") {
            window.progressEventTimer = [];
          }

          if (typeof window.progressEventTimer['StepEvent'] != "undefined") {
            let timeout = window.progressEventTimer['StepEvent'];
            clearTimeout(timeout);
          }
          window.progressEventTimer['StepEvent'] = setTimeout(() => {
            this.isLoading = true;
            this.eventSrcLoading.push('StepEvent');

            CalendarApiService.getProgressEvents(selectedSteps, start, end, filterModel).then((response) => {
              this.setResponseEventSrc(response.data, 'StepEvent', successCallback, selectedSteps)
            });
          }, timeout);
        }
      };

      return callback;
    },
    setResponseEventSrc(data, type, successCallback, resources) {
      let events = [];
      let appointments = data;
      for (let app of appointments) {
        if (type == 'BlockerEvent') {
          app.eventType = type;
        }
        let model = EventService.getCalendarEventModel(app, resources);
        events.push(...model);
      }

      successCallback(events);

      //Pour gerer le loading overloay qui doit disparaitre seulement apres que le dernier event soit load
      const index = this.eventSrcLoading.indexOf(type);
      if (index > -1) {
        this.eventSrcLoading.splice(index, 1);
      }
      if (this.eventSrcLoading.length == 0) {
        this.isLoading = false;
      }
    },
    getWorkOrderFilterModel() {
      return {
        workOrderName:
          this.state.workOrderSearchInput == null || this.state.workOrderSearchInput == ""
            ? null
            : this.state.workOrderSearchInput,
        workOrderTypeIds: this.$store.state.calendar.workOrderTypes.length == 0
          ? null
          : this.$store.state.calendar.workOrderTypes.map((wt) => {
            return wt.idPublic
          })
      };
    },
    getInstallationEventSrcCallBack() {
      let callback = (info, successCallback) => {
        if(this.state.sources.installers.length === 0) {
          this.setResponseEventSrc([], 'InstallationEvent', successCallback, this.state.sources.installers);
        } else {
          let start = this.$dayjs(info.start).format("YYYY-MM-DD");
          let end = this.$dayjs(info.end).format("YYYY-MM-DD");
          let filterModel = this.getWorkOrderFilterModel();

          //Debounce pour ne pas pouvoir spammer le bouton next/ previous
          var timeout = this.state.setSrc == true ? 0 : 500;
          if (typeof window.installationEventTimer == "undefined") {
            window.installationEventTimer = [];
          }

          if (typeof window.installationEventTimer['InstallationEvent'] != "undefined") {
            let timeout = window.installationEventTimer['InstallationEvent'];
            clearTimeout(timeout);
          }
          window.installationEventTimer['InstallationEvent'] = setTimeout(() => {
            this.isLoading = true;
            this.eventSrcLoading.push('InstallationEvent');

            CalendarApiService.getInstallationEvents(this.state.sources.installers, start, end, filterModel).then((response) => {
              this.setResponseEventSrc(response.data, 'InstallationEvent', successCallback, this.state.sources.installers)
            });

          }, timeout);
        }
      };

      return callback;
    },
    getDeliveryEventSrcCallBack() {
      let callback = (info, successCallback) => {
        if(this.state.sources.shippers.length === 0) {
          this.setResponseEventSrc([], 'DeliveryEvent', successCallback, this.state.sources.shippers);
        } else {
          let start = this.$dayjs(info.start).format("YYYY-MM-DD");
          let end = this.$dayjs(info.end).format("YYYY-MM-DD");
          let filterModel = this.getWorkOrderFilterModel();

          //Debounce pour ne pas pouvoir spammer le bouton next/ previous
          var timeout = this.state.setSrc == true ? 0 : 500;
          if (typeof window.deliveryEventTimer == "undefined") {
            window.deliveryEventTimer = [];
          }

          if (typeof window.deliveryEventTimer['DeliveryEvent'] != "undefined") {
            let timeout = window.deliveryEventTimer['DeliveryEvent'];
            clearTimeout(timeout);
          }
          window.deliveryEventTimer['DeliveryEvent'] = setTimeout(() => {
            this.isLoading = true;
            this.eventSrcLoading.push('DeliveryEvent');

            CalendarApiService.getDeliveryEvents(this.state.sources.shippers, start, end, filterModel).then((response) => {
              this.setResponseEventSrc(response.data, 'DeliveryEvent', successCallback, this.state.sources.shippers);
            });
          }, timeout);
        }
      };

      return callback;
    },
    getBlockerEventSrcCallBack() {
      let callback = (info, successCallback) => {
        if(this.state.blockerSourceReady === false || this.state.sources.steps.length + this.state.sources.shippers.length + this.state.sources.installers.length === 0) {
          this.setResponseEventSrc([], 'BlockerEvent', successCallback);
        } else {  
          let start = this.$dayjs(info.start).format("YYYY-MM-DD");
          let end = this.$dayjs(info.end).format("YYYY-MM-DD");

          //Debounce pour ne pas pouvoir spammer le bouton next/ previous
          var timeout = this.state.setSrc == true ? 0 : 500;

          if (typeof window.blockerEventTimer != "undefined") {
            let timeout = window.blockerEventTimer;
            clearTimeout(timeout);
          }
          window.blockerEventTimer = setTimeout(() => {
            this.isLoading = true;
            this.eventSrcLoading.push('BlockerEvent');
            let selectedProgress = this.state.sources.steps.map((x) => { return x.id;});
            let selectedInstallers = this.state.sources.installers.filter(x => !x.id.includes('00000000-0000-0000-0000-000000000000')).map((x) => { return x.id;});
            let selectedShippers = this.state.sources.shippers.filter(x => !x.id.includes('00000000-0000-0000-0000-000000000000')).map((x) => { return x.id;});
            CalendarApiService.postGetBlockerEvents(
              start,
              end,
              1,
              selectedProgress,
              selectedInstallers,
              selectedShippers
            ).then((response) => {
              if (response.status === 200) {

                this.setResponseEventSrc(
                  response.data,
                  'BlockerEvent',
                  successCallback
                );
              }
            });
          }, timeout);
        }
      };

      return callback;
    },
    getProductionEventSrcCallBack() {
      let callback = (info, successCallback) => {
        if(this.state.sources.production.length === 0) {
            this.setResponseEventSrc([], 'ProductionEvent', successCallback);
        } else {
          let start = this.$dayjs(info.start).format("YYYY-MM-DD");
          let end = this.$dayjs(info.end).format("YYYY-MM-DD");
          let filterModel = this.getWorkOrderFilterModel();

          //Debounce pour ne pas pouvoir spammer le bouton next/ previous
          var timeout = this.state.setSrc == true ? 0 : 500;

          if (typeof window.productionEventTimer != "undefined") {
            let timeout = window.productionEventTimer;
            clearTimeout(timeout);
          }
          window.productionEventTimer = setTimeout(() => {
            this.isLoading = true;
            this.eventSrcLoading.push('ProductionEvent');
            CalendarApiService.getProductionEvents(start, end, filterModel).then((response) => {
              this.setResponseEventSrc(response.data, 'ProductionEvent', successCallback);
            });
          }, timeout);
        }
      };

      return callback;
    },
    getUserEventSrcCallBack() {
      let callback = (info, successCallback) => {
        if(this.state.blockerSourceReady === false || this.state.sources.steps.length + this.state.sources.shippers.length + this.state.sources.installers.length === 0) {
          this.setResponseEventSrc([], 'UserEvent', successCallback);
        } else {
          let start = this.$dayjs(info.start).format("YYYY-MM-DD");
          let end = this.$dayjs(info.end).format("YYYY-MM-DD");

          // Debounce pour ne pas pouvoir spammer le bouton next / previous
          var timeout = this.state.setSrc == true ? 0 : 500;

          if (typeof window.userEventTimer != "undefined") {
            let timeout = window.userEventTimer;
            clearTimeout(timeout);
          }
          window.userEventTimer = setTimeout(() => {
            this.isLoading = true;
            this.eventSrcLoading.push('UserEvent');
            let selectedProgress = this.state.sources.steps.map((x) => { return { id: x.id, stepUserIds: null };});
            let selectedInstallers = this.state.sources.installers.filter(x => !x.id.includes('00000000-0000-0000-0000-000000000000')).map((x) => { return x.id;});
            let selectedShippers = this.state.sources.shippers.filter(x => !x.id.includes('00000000-0000-0000-0000-000000000000')).map((x) => { return x.id;});
            CalendarApiService.getUserEvents(start, end, selectedProgress, selectedInstallers, selectedShippers).then((response) => {
              this.setResponseEventSrc(response.data, 'UserEvent', successCallback);
            });
          }, timeout);
        }
      };

      return callback;
    },
    refetchEventSource(eventSourceId) {
      this.$refs.fullCalendar.getApi().getEventSourceById(eventSourceId)?.refetch();
    },
    removeEventSource(eventSourceId) {
      let src = this.$refs.fullCalendar.getApi().getEventSourceById(eventSourceId);
      if (src != null) {
        src.remove();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.fullCalendar
        .getApi()
        .setOption("height", this.$el.clientHeight);
    }, 5);
  },
  computed: {
    calendarOptions() {
      return {
        ...this.internalCalendarOptions,
        ...this.options.fullCalendarOptions,
        resources: [...this.state.sources.steps,
            ...this.state.sources.installers,
            ...this.state.sources.shippers,
            ...this.state.sources.production
        ],
      }
    },
    currentCalendarMonth() {
      this.state.updateDisplayMonth;
      if (this.state.view == "dayGridMonth") {
        return formatDate(this.$refs.fullCalendar.getApi().getDate(), {
          month: "long",
          year: "numeric",
          locale: this.$i18n.locale,
        });
      }
      if (this.state.view == "timeGridDay") {
        return formatDate(this.$refs.fullCalendar.getApi().getDate(), {
          month: "long",
          year: "numeric",
          day: "numeric",
          locale: this.$i18n.locale,
        });
      } 
      if (this.state.view == "workWeek") {
        let firstday = new Date(
          this.$refs.fullCalendar.getApi()
            .getDate()
            .setDate(
              this.$refs.fullCalendar.getApi().getDate().getDate() -
                this.$refs.fullCalendar.getApi().getDate().getDay() +
                1
            )
        );
        let lastday = new Date(
          this.$refs.fullCalendar.getApi()
            .getDate()
            .setDate(
              this.$refs.fullCalendar.getApi().getDate().getDate() -
                this.$refs.fullCalendar.getApi().getDate().getDay() +
                5
            )
        );
        return formatRange(firstday, lastday, {
          month: "long",
          year: "numeric",
          day: "numeric",
          separator: " - ",
          locale: this.$i18n.locale,
        });
      }
      let firstday = new Date(
        this.$refs.fullCalendar.getApi()
        .getDate()
        .setDate(this.$refs.fullCalendar.getApi().getDate().getDate() - this.$refs.fullCalendar.getApi().getDate().getDay() + 1));
        let lastday = new Date(
          this.$refs.fullCalendar.getApi()
            .getDate()
            .setDate(
              this.$refs.fullCalendar.getApi().getDate().getDate() -
                this.$refs.fullCalendar.getApi().getDate().getDay() +
                7
            )
        );
        return formatRange(firstday, lastday, {
          month: "long",
          year: "numeric",
          day: "numeric",
          separator: " - ",
          locale: this.$i18n.locale,
        });
    },
  },
  watch: {
    "$i18n.localeComplete": function () {
      this.$refs.fullCalendar
        .getApi()
        .setOption("locale", this.$i18n.localeComplete);
    },
    'state.sources.steps': {
      deep:true,
      handler: function() {
        this.refetchEventSource('StepEvent');
        this.refetchEventSource('BlockerEvent');
        this.refetchEventSource('UserEvent');
      }
    },
    'state.sources.shippers': function() {
      this.refetchEventSource('DeliveryEvent');
      this.refetchEventSource('BlockerEvent');
      this.refetchEventSource('UserEvent');
    },
    'state.sources.installers': function() {
      this.refetchEventSource('InstallationEvent');
      this.refetchEventSource('BlockerEvent');
      this.refetchEventSource('UserEvent');
    },
    'state.sources.production': function() {
      this.refetchEventSource('ProductionEvent');
    }
  },
};
</script>
<template>
  <div
    id="calendarContainer"
    class=""
    style="
      flex: 1 1 0;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 3.2px 7.2px 0px,
        rgba(255, 255, 255, 0) 0px 0.6px 1.8px 0px;
      position: relative;
      overflow-y: auto;
    "
  >
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :opacity="0.5"
      :height="70"
      loader="dots"
      :is-full-page="false"
    ></loading>
    <FullCalendar
      ref="fullCalendar"
      style="
        max-height: 100%;
        padding: 15px;
        border-radius: 10px;
        background: white;
      "
      :options="calendarOptions"
    >
      <template v-slot:eventContent="arg">
        <EventTemplate
          v-model="arg.event"
          :view="arg.view"
          :fields="options.iconFields"
          :mobileScreen="options.mobileScreen"
        />
      </template>
      <template v-slot:resourceLabelContent="arg">
        <i
          v-if="arg.resource.extendedProps.resourceType == 'InstallationEvent'"
          class="mdi mdi-hammer-screwdriver mr-1"
          style="font-size: 1.2em"
        />
        <div
          v-if="arg.resource.extendedProps.resourceType == 'StepEvent'"
          style="width: 1px; display: inline"
          :style="{ 'background-color': arg.resource.extendedProps.color }"
          class="mr-1 rounded d-inline"
        >
          &nbsp;
        </div>
        <i
          v-if="arg.resource.extendedProps.resourceType == 'ProductionEvent'"
          class="fas fa-cogs mr-1"
          style="font-size: 1.2em"
        />
        <i
          v-if="arg.resource.extendedProps.resourceType == 'DeliveryEvent'"
          class="mdi mdi-truck mr-1"
          style="font-size: 1.2em"
        />
        <i
          class="bx bx-user-circle orange-icon ml-1 font-weight-bold align-middle font-size-14"
          v-if="arg.resource.extendedProps.resourceType == 'ProgressUser'"
        ></i>
        {{ arg.resource.title }}
      </template>
    </FullCalendar>
  </div>
</template>

<style scoped>
</style>