<script>
import CalendarApiService from "@/services/calendarService";

export default {
  components: {

  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      workOrdersSearchList: [],
      isLoading: false,
      isOpenSearch: false,
      searchItem: null,
      mouseInBox: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    top100(){
      if(this.workOrdersSearchList != null){
        return this.workOrdersSearchList.slice(0, 100)
      }else{
        return [];
      }
    }
  },  
  methods:{
    asyncFindWorkOrder(){

      if(typeof window.LIT !== 'undefined') {
          clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        if(this.model == "" || this.model == null){
          return;
        }
        
        this.isLoading = true;
        this.workOrdersSearchList = [];
        CalendarApiService.findWorkOrderByName(this.model).then((response) => {
          this.isLoading = false;

          let workOrders = response.data.data;
          this.workOrdersSearchList = workOrders;

          this.$refs.searchList.style.height = "auto"
          this.$refs.searchList.style.overflowY = "auto"

          this.isOpenSearch = true;

          //verif hauteur de la list et resize si over window height
          this.$nextTick(() => {
            var workOrderListRect = this.$refs.searchList.getBoundingClientRect();
            if(workOrderListRect.bottom >= document.documentElement.clientHeight){
              var toRemove = workOrderListRect.bottom - window.innerHeight
              this.$refs.searchList.style.height = workOrderListRect.bottom - toRemove - 150 + "px"
              this.$refs.searchList.style.overflowY = "auto"
            }else{
              this.$refs.searchList.style.height = "auto"
              this.$refs.searchList.style.overflowY = "auto"
            }
          });

        });
      }, 500);
    },
    setActive(workOrder){
      for(var i = 0; i < this.workOrdersSearchList.length; i++){
        this.$set(this.workOrdersSearchList[i], 'isActive', false)
      }
      this.$set(workOrder, 'isActive', true)
    },
    searchInputDown(){
      //highlight next work order
      var currentActiveIndex = this.workOrdersSearchList.findIndex((item) => {
        return item.isActive == true;
      });

      if(currentActiveIndex != -1){
        if(currentActiveIndex < this.workOrdersSearchList.length - 1){
          var nextIndex = currentActiveIndex + 1;
          this.setActive(this.workOrdersSearchList[nextIndex]);
        }
      }else if(this.workOrdersSearchList.length > 0){
        this.setActive(this.workOrdersSearchList[0])
      }
    },
    searchInputUp(){
      //highlight next work order
      var currentActiveIndex = this.workOrdersSearchList.findIndex((item) => {
        return item.isActive == true;
      });

      if(currentActiveIndex != -1){
        if(currentActiveIndex > 0){
          var beforeIndex = currentActiveIndex - 1;
          this.setActive(this.workOrdersSearchList[beforeIndex]);
        }
      }else if(this.workOrdersSearchList.length > 0){
        this.setActive(this.workOrdersSearchList[this.workOrdersSearchList.length - 1])
      }
    },
    searchExplicit(){
      if(this.model != null && this.model != ''){
        this.workOrdersSearchList = [];
        this.isOpenSearch = false;
        this.$emit('search') 
      } else {
        this.$emit('reload')
      }
     
    },
    search(){
      if(this.isOpenSearch == true){
        //active
        var active = this.workOrdersSearchList.filter((item) => {return item.isActive == true;})
        if(active.length > 0){
          this.selectItem(active[0])
        }else{
          this.workOrdersSearchList = [];
          this.isOpenSearch = false;
          this.$emit('search')
        }
      }else{
        if(this.model != null && this.model != ''){
          this.workOrdersSearchList = [];
          this.isOpenSearch = false;
          this.$emit('search')
        } else {
          this.$emit("reload")
        }
      }
    },
    selectItem(workOrder){
      this.workOrdersSearchList = [];
      this.model = workOrder.name;
      this.isOpenSearch = false;
      this.$emit('search')
    },
    inputBlur(){
      if(!this.mouseInBox){
        this.isOpenSearch = false;
      }
    },
    focusInput(){
      //unselect active
      for(var i = 0; i < this.workOrdersSearchList.length; i++){
        this.$set(this.workOrdersSearchList[i], 'isActive', false)
      }

      if(this.workOrdersSearchList != null && this.workOrdersSearchList.length > 0){
        this.isOpenSearch = true;
      }
    },
    mouseover(){
      this.mouseInBox = true;
    },
    mouseleave(){
      this.mouseInBox = false;
    },
    clear(){
      this.workOrdersSearchList = [];
      this.model = null;
      this.$emit('clear')
    },
    showOnCalendar(workOrder){
      this.workOrdersSearchList = [];
      this.model = workOrder.name;
      this.isOpenSearch = false;      
      this.$emit('showOnCalendar', workOrder)
    }
  }
};
</script>
<template>
  <div class="mb-0" style="display:inline-block; position:relative;" @mouseover="mouseover" @mouseleave="mouseleave">
    <div style="display:flex; position:relative;">
      <b-form-input type="text"
        style="border-bottom-right-radius:0;border-top-right-radius:0; width:220px;"
        :placeholder="$t('general.searchWorkOrder')"
        autocomplete="off"
        v-model="model"
        @input="asyncFindWorkOrder"
        @blur="inputBlur"
        @focus="focusInput"
        @keydown.enter="search"
        @keydown.down="searchInputDown"
        @keydown.up="searchInputUp"
        /> 
      <b-button @click="searchExplicit" class="workOrderSearchButton" style="display:flex; align-items:center;">
        <i class="bx bx-search"></i>
      </b-button>
      <i class="bx bx-x" v-if="model != null && model != ''" @click="clear()" style="cursor: pointer; font-size:1.4em; position:absolute; top:10px; right:45px;"></i>
      <b-spinner v-if="isLoading" small style="color:rgba(243, 168, 33, 0.7) !important; position:absolute; right:70px; top:10px;" variant="success" label="Spinning"></b-spinner>
      <ul class="workOrderSearchList" ref="searchList" v-show="isOpenSearch == true && workOrdersSearchList != null && workOrdersSearchList.length > 0">
        <li 
          v-for="(workOrder, index) in top100" :key="index" 
          class="matchItem" :class="{ 'activeSearchMatch' : workOrder.isActive }" 
          @click="selectItem(workOrder)" 
          @mouseover="setActive(workOrder)" 
          style="cursor:pointer;display: flex; align-items: center; justify-content: space-between;">
          <span v-text="workOrder.name"></span>
          <span @click.stop="showOnCalendar(workOrder)" class="showOnCalendarBtn" style="padding:2px 5px;">
            <span class="bx bx-log-in" style="position:relative; top:2px; font-size:2em; color:white;"></span>
          </span>
        </li>
      </ul> 
    </div>     
  </div>
</template>