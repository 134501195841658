var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simplebar',{ref:"calendarToolBar",class:{
    calendarToolsContent: true,
    row: true,
    'no-gutters': true,
    withNotification: _vm.hasGeneralNotification,
  },staticStyle:{"padding":"0","padding-top":"1.5rem !important","position":"absolute","overflow":"auto","background":"#f1f1f2"},attrs:{"settings":_vm.settings,"id":"calendar-tool-bar"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"padding-left":"10px","padding-right":"10px"}},[_c('div',{staticClass:"mb-3",class:{ 'flex-column': !_vm.isTaskManagementOrganisationUser },staticStyle:{"display":"flex","justify-content":"space-between"}},[(!_vm.isTaskManagementOrganisationUser)?_c('div',{staticStyle:{"width":"100%"},attrs:{"id":"workOrderFilters"}},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v(_vm._s(_vm.$t("general.workOrdersType")))]),_c('multiselect',{attrs:{"options":_vm.workOrdersTypesOptions,"label":"name","track-by":"idPublic","show-labels":false,"multiple":true,"placeholder":_vm.$t('general.selectWorkOrderTypes')},on:{"input":_vm.selectWorkOrderType},model:{value:(_vm.workOrderTypeFilter),callback:function ($$v) {_vm.workOrderTypeFilter=$$v},expression:"workOrderTypeFilter"}})],1)]):_vm._e(),_c('div',{class:{
            task_mngm_date: _vm.isTaskManagementOrganisationUser,
            'mt-3': !_vm.isTaskManagementOrganisationUser,
            'mb-3': _vm.isTaskManagementOrganisationUser,
          }},[_c('date-picker',{staticClass:"sideBarDatePicker",attrs:{"clearable":false,"format":this.$dayjs.localeData().longDateFormat('L'),"value-type":"YYYY-MM-DD","first-day-of-week":1,"lang":"fr"},on:{"change":_vm.changeDate},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('i',{staticClass:"fas fa-chevron-left",class:{
            'closeToolBar-task-mngm': _vm.isTaskManagementOrganisationUser,
            'closeToolBar-wo-task-mngm': !_vm.isTaskManagementOrganisationUser,
          },staticStyle:{"position":"absolute","right":"12px","height":"fit-content","font-size":"2em","color":"#cacaca","margin-left":"15px","margin-bottom":"5px","cursor":"pointer"},on:{"click":function($event){return _vm.closeToolBar()}}})]),(_vm.progressCapacitiesData.length > 0)?_c('div',{staticClass:"card mb-3 infoContainer",attrs:{"id":"capacityContainer"}},[_c('b-button',{staticClass:"mr-2 infoContainer",staticStyle:{"border":"none","background":"white","display":"flex","width":"100%","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.toggleCapacityContainer()}}},[_vm._v(_vm._s(_vm.$t("general.capacitiesProgress"))+" "),_c('i',{class:{
              'fas fa-angle-down': !_vm.capacityContainerOpened,
              'fas fa-angle-up': _vm.capacityContainerOpened,
            }})]),_c('b-collapse',{model:{value:(_vm.capacityContainerOpened),callback:function ($$v) {_vm.capacityContainerOpened=$$v},expression:"capacityContainerOpened"}},[_c('b-card',{staticStyle:{"margin-top":"-6px","margin-bottom":"0"}},[_c('loading',{attrs:{"active":_vm.isLoadingProgressCapacities,"can-cancel":false,"color":"#f1b44c","width":70,"height":70,"loader":"dots","is-full-page":false},on:{"update:active":function($event){_vm.isLoadingProgressCapacities=$event}}}),_vm._l((_vm.progressCapacitiesData),function(capacity,index){return _c('div',{key:index,staticClass:"mb-3"},[_c('b-progress',{staticClass:"mb-1",class:{
                  underCapacity: capacity.totalCompleted + capacity.totalNotCompleted <= capacity.min,
                  overCapacity: capacity.totalCompleted + capacity.totalNotCompleted >= capacity.max,
                  inRangeCapacity:
                    capacity.totalCompleted + capacity.totalNotCompleted >= capacity.min &&
                    capacity.totalCompleted + capacity.totalNotCompleted<= capacity.max,
                },attrs:{"height":"20px","max":capacity.max,"show-value":""}},[_c('b-progress-bar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"value":capacity.totalCompleted,"title":capacity.totalCompleted}}),_c('b-progress-bar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:{
                    underCapacityNotCompleted: capacity.totalCompleted + capacity.totalNotCompleted <= capacity.min,
                    overCapacityNotCompleted: capacity.totalCompleted + capacity.totalNotCompleted >= capacity.max,
                    inRangeCapacityNotCompleted:
                      capacity.totalCompleted + capacity.totalNotCompleted >= capacity.min &&
                      capacity.totalCompleted + capacity.totalNotCompleted<= capacity.max,
                  },attrs:{"title":capacity.totalNotCompleted,"value":capacity.totalNotCompleted,"animated":""}})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('small',{staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.$t("general." + capacity.measureType))+" : "+_vm._s(capacity.totalCompleted + capacity.totalNotCompleted)+" ")]),_c('small',[_vm._v(_vm._s(capacity.min)+" | "+_vm._s(capacity.max))])])],1)})],2)],1)],1):_vm._e(),_c('InstallersCapacities',{attrs:{"isLoading":_vm.isLoadingInstallersCapacities,"capacities":_vm.installersCapacitiesData}}),_c('SourceSelector',{key:"progress",ref:"sourceSelector",attrs:{"options":_vm.sourcesOptions,"isLoading":_vm.isLoading},on:{"change":function () { return this$1.$emit('sourceChange', this$1.state.sources); }},model:{value:(_vm.state.sources),callback:function ($$v) {_vm.$set(_vm.state, "sources", $$v)},expression:"state.sources"}}),(_vm.selectedWorkOrder != null)?_c('WorkOrderInfoPanel',{on:{"btn-delete-clicked":_vm.deleteSelectedWorkOrder,"edit-event":_vm.editEvent},model:{value:(_vm.selectedWorkOrder),callback:function ($$v) {_vm.selectedWorkOrder=$$v},expression:"selectedWorkOrder"}}):_vm._e(),(_vm.userCanSeeAnyProgressInLegend())?_c('div',{staticClass:"card mb-2 infoContainer shadow-none",attrs:{"id":"legendContainer"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapseLegend'),expression:"'collapseLegend'"}],staticClass:"mr-2 infoContainer",staticStyle:{"border":"none","background":"white","display":"flex","width":"100%","justify-content":"space-between","align-items":"center"},on:{"click":function($event){return _vm.toggleLegendContainer()}}},[_vm._v(_vm._s(_vm.$t("general.legend"))+" "),_c('i',{class:{
              'fas fa-angle-down': !_vm.legendContainerOpened,
              'fas fa-angle-up': _vm.legendContainerOpened,
            }})]),_c('b-collapse',{attrs:{"id":"collapseLegend"}},[_c('b-card',{staticClass:"shadow-none",staticStyle:{"margin-top":"-6px","margin-bottom":"0"}},[_c('ul',{staticStyle:{"list-style-type":"none","padding-left":"0"}},_vm._l((_vm.sortedProgressVisibleInLegend),function(progress,index){return _c('li',{key:progress.id},[_c('b-badge',{staticClass:"mb-2",staticStyle:{"font-size":"1.2em"},style:({ 'background-color': progress.colorHexa }),attrs:{"pill":""}},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(progress.name)+" ")])],1)}),0)])],1)],1):_vm._e()],1)]),_c('DeletionModal',{ref:"workOrderDeletionModal",attrs:{"content":this.$t('general.areYouSureDeleteWorkOrder'),"title":this.$t('general.deleteWorkOrder')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }